import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {
  Autocomplete,
  Box,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CreateCampaign } from 'Api/Api';
import { SideDrawer } from 'components/SideDrawer';
import { getCurrentDateTimeLocal } from 'utils/commonFunctions';
import CreateTemplateModal from '../Templates/CreateTemplateModal';
import { CampaignStyle } from './campaignStyle';
import { Loading } from 'components/Loading';
import DateTimePicker from 'components/DateTimePicker';
import { InnerTextField } from 'components/InputFields';
import { CampaignScheuduler } from 'constants/appConstant';
import { CustomSelect } from 'components/CustomSelect';
import CreateSegmentModal from '../Segments/CreateSegmentModal';

const CreateCampaignModal = ({
  open,
  onClose,
  refresh,
  templates = [],
  segments = [],
  FetchTemplate,
  FetchSegment,
}) => {
  const [campaignDetails, setCampaignDetails] = useState({
    campaignName: '',
    templateID: null,
    segmentID: null,
    frequency: 'now',
    frequencyDateTime: '',
    status: 'Active',
  });
  const [errors, setErrors] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [selectedSegment, setSelectedSegment] = useState();
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [segmentModalOpen, setSegmentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModals = () => {
    setTemplateModalOpen(false);
    setSegmentModalOpen(false);
  };

  const handleToggle = () => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      status: prevState.status === 'Active' ? 'Inactive' : 'Active',
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails((prev) => ({
      ...prev,
      [name]: value || null,
    }));
    setErrors((prev) => ({ ...prev, [name]: !value.trim() }));
  };

  const handleFrequencyChange = (event) => {
    const { value } = event.target;
    setCampaignDetails((prev) => ({
      ...prev,
      frequency: value,
      frequencyDateTime:
        value !== 'on_specific_date'
          ? getCurrentDateTimeLocal()
          : prev.frequencyDateTime,
    }));
    setErrors((prev) => ({ ...prev, frequency: !value.trim() }));
  };

  const handleSubmit = async () => {
    const newErrors = {
      campaignName: !campaignDetails.campaignName.trim(),
      templateID: campaignDetails.templateID === null,
      segmentID: campaignDetails.segmentID === null,
    };
    setErrors(newErrors);

    if (Object.values(newErrors).every((val) => !val)) {
      setLoading(true);
      try {
        let formData = new FormData();
        Object.entries(campaignDetails).forEach(([key, value]) => {
          if (key === 'templateID' || key === 'segmentID') {
            formData.append(key, value ? parseInt(value) : null);
          } else {
            formData.append(key, value);
          }
        });
        const response = await CreateCampaign(formData);
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refresh();
          onClose();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (campaignDetails.frequency === 'now') {
      setCampaignDetails((prev) => ({
        ...prev,
        frequencyDateTime: getCurrentDateTimeLocal(),
      }));
    }
  }, [campaignDetails.frequency]);

  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={onClose}
        title={'Create Campaign'}
        handleSubmit={handleSubmit}
      >
        <Box>
          <InnerTextField
            required
            name="campaignName"
            label="Campaign Name"
            onChange={handleChange}
            error={errors.campaignName}
            value={campaignDetails?.campaignName || ''}
            helperText={errors.campaignName && 'Field required'}
          />
        </Box>
        <Box sx={CampaignStyle.selectInputFlex} my={3}>
          <Autocomplete
            disablePortal
            options={templates}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.templateName}
            value={selectedTemplate}
            onChange={(e, newValue) => {
              setSelectedTemplate(newValue);
              setCampaignDetails((prev) => ({
                ...prev,
                templateID: newValue ? parseInt(newValue.id) : null,
              }));
              setErrors((prev) => ({ ...prev, templateID: !newValue }));
            }}
            sx={CampaignStyle.autoSelectStyle}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Template"
                error={errors.templateID}
                helperText={errors.templateID && 'Field required'}
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{ ...params.InputProps, style: { color: 'black' } }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} style={{ color: 'black' }}>
                {option.templateName}
              </li>
            )}
          />
          <IconButton onClick={() => setTemplateModalOpen(true)}>
            <ControlPointIcon
              sx={{ '& path': { fill: '#058270' }, fontSize: 35 }}
            />
          </IconButton>
          <CreateTemplateModal
            refresh={FetchTemplate}
            open={templateModalOpen}
            onClose={closeModals}
            welcome={false}
          />
        </Box>

        <Box sx={CampaignStyle.selectInputFlex} my={3}>
          <Autocomplete
            disablePortal
            options={segments}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.segmentName}
            value={selectedSegment}
            onChange={(e, newValue) => {
              setSelectedSegment(newValue);
              setCampaignDetails((prev) => ({
                ...prev,
                segmentID: newValue ? parseInt(newValue.id) : null,
              }));
              setErrors((prev) => ({ ...prev, segmentID: !newValue }));
            }}
            sx={CampaignStyle.autoSelectStyle}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Segment"
                error={errors.segmentID}
                helperText={errors.segmentID && 'Field required'}
                InputLabelProps={{ style: { color: 'black' } }}
                InputProps={{ ...params.InputProps, style: { color: 'black' } }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} style={{ color: 'black' }}>
                {option.segmentName}
              </li>
            )}
          />
          <IconButton onClick={() => setSegmentModalOpen(true)}>
            <ControlPointIcon
              sx={{ '& path': { fill: '#058270' }, fontSize: 35 }}
            />
          </IconButton>
          <CreateSegmentModal
            refresh={FetchSegment}
            open={segmentModalOpen}
            onClose={closeModals}
          />
        </Box>

        <Box mb={2}>
          <Typography variant="black_4">Status</Typography>
          <Switch
            name="status"
            size="large"
            checked={campaignDetails?.status === 'Active'}
            onChange={handleToggle}
          />
        </Box>

        <Box width="100%" display={'flex'} alignItems={'end'} gap={2}>
          <FormControl sx={{ width: '44%' }}>
            <CustomSelect
              label="Schedule"
              margin="normal"
              fullWidth
              error={errors.frequency}
              options={CampaignScheuduler}
              value={campaignDetails?.frequency}
              onChange={handleFrequencyChange}
              helperText={errors.frequency ? 'Field required' : ''}
            />
          </FormControl>

          {/* Need to change this component with Date time picker of MUI */}
          {campaignDetails.frequency !== 'now' && (
            <DateTimePicker
              label={'Date and time'}
              onChangeval={(e) => {
                setCampaignDetails({
                  ...campaignDetails,
                  frequencyDateTime: e,
                });
              }}
            />
          )}
        </Box>
      </SideDrawer>
    </>
  );
};

export default CreateCampaignModal;
