import React, { useEffect, useState } from 'react';
import { Box, Skeleton, TextField, Typography } from '@mui/material';
import { UpdateConnection } from 'Api/Api';
import toast from 'react-hot-toast';
import { Loading, SkeletonLoading } from 'components/Loading';
import { SideDrawer } from 'components/SideDrawer';
import { imageFileInput } from '../Templates/Style';
import { InnerTextField } from 'components//InputFields';
import { formatDateTime, imageValidation } from 'utils/commonFunctions';

const EditConnectModal = ({ open, onClose, itemId, isDetail, refresh }) => {
  const [connectionDetails, setConnectionDetails] = useState({});
  const [updateConnection, setUpdateConnection] = useState({
    id: itemId?.id,
  });
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({
    connectionName: false,
  });

  const handleEditChange = () => {
    setEdit(!edit);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConnectionDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setUpdateConnection((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === '' ? true : false,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'connectionImage') {
      const file = files[0];
      if (file) {
        const isValid = imageValidation(file, setErrors);
        if (isValid) {
          setConnectionDetails((prevState) => ({
            ...prevState,
            [name]: file,
          }));
          setUpdateConnection((prevState) => ({
            ...prevState,
            [name]: file,
          }));
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: !file,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {
      connectionName: connectionDetails.connectionName.trim() === '',
    };
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      try {
        setLoading(true);
        let formData = new FormData();
        Object.entries(updateConnection).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const response = await UpdateConnection(
          connectionDetails?.id,
          formData
        );
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          refresh();
          onClose();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    const { imageURL, ...restItemId } = itemId || {};
    setConnectionDetails(restItemId);
    setUpdateConnection({ id: restItemId.id });
    if (imageURL) {
      setImage(imageURL);
    }
  }, [itemId]);


  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={onClose}
        isDetail={isDetail}
        edit={edit}
        setEdit={handleEditChange}
        title={!edit && isDetail ? 'Connection Details' : 'Edit Connection'}
        handleSubmit={handleSubmit}
      >
        <Box>
          <InnerTextField
            required
            error={errors.connectionName}
            name="connectionName"
            label="Connection Name"
            value={connectionDetails?.connectionName || ''}
            placeholder="Enter connection name"
            onChange={handleChange}
            helperText={errors.connectionName ? 'Field required' : ''}
            readOnly={!edit && isDetail}
          />
        </Box>
        <br />
        <Typography variant="subtitle3">Connection Image</Typography>
        <Box>
          <TextField
            type="file"
            name="connectionImage"
            fullWidth
            margin="dense"
            onChange={handleFileChange}
            disabled={!edit && isDetail}
            sx={imageFileInput}
          />
        </Box>
        {image && (
          <img src={image} alt="Connection Image" width={200} height={500} />
        )}
        <Box>
          {isDetail && !edit ? (
            <Box>
              <Typography variant="subtitle2" my={1}>
                Created time : {formatDateTime(connectionDetails?.createdTime)}
              </Typography>
              <Typography variant="subtitle2" my={1}>
                Modified time :{' '}
                {formatDateTime(connectionDetails?.modifiedTime)}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </SideDrawer>
    </>
  );
};

export default EditConnectModal;
