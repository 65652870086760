import React, { useEffect, useState } from 'react';
import NoRecord from 'components/NoRecord';
import CreateTemplateModal from 'pages/protected/Templates/CreateTemplateModal';
import Details from 'pages/protected/Templates/Enlarge/Details';
import { FetchAllTemplate } from 'Api/Api';
import toast from 'react-hot-toast';
import { CircularProgress, Skeleton, Typography } from '@mui/material';
import { SkeletonLoading } from 'components/Loading';

export const Welcome = ({ item,welcomeTemplate,refreshTemplate,refresh,setWelcomeTemplates }) => {
  const [openModal, setOpenModal] = useState(false);
  const [flag, setFlag] = useState(welcomeTemplate !== null);
  const [error, setError] = useState(null);


  const handleCreateModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setFlag(welcomeTemplate !== null)
  }, [welcomeTemplate]);

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      {flag ? (
        <Details item={welcomeTemplate} onClose={handleClose} refresh={refresh} refreshTemplate={refreshTemplate} 
        setWelcomeTemplates={setWelcomeTemplates}
        />
      ) : (
        !flag && (
          <>
            <NoRecord
              type="callback"
              moduleName="Welcome Template"
              onAction={handleCreateModal}
            />
            <CreateTemplateModal
              onClose={handleClose}
              open={openModal}
              item={item}
              welcome={true}
              refresh={refresh} 
              refreshTemplate={refreshTemplate}              
            />
          </>
        )
      )}

    </>
  );
};
