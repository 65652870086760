import React, { useEffect, useMemo } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MiniMap,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import AddNode from './components/Nodes/addNode';
import CustomNode from './components/Nodes/customNode';
import StartNode from './components/Nodes/startNode';
import { Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateSequence } from '../../../redux/reducers/sequenceReducer';

const SequenceBoard = ({ handleBack, handleNext }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  const { nodes: reduxNodes, edges: reduxEdges } = useSelector(
    (state) => state.sequence.sequence
  );
  const [nodes, setNodes, onNodesChange] = useNodesState(reduxNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(reduxEdges);
  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  function UpdateNodeAndEdge() {
    dispatch(updateSequence({ nodes, edges }));
  }

  const PreviousPage = () => {
    if (id) {
      navigate('/sequence/' + id + '/subscriber');
    } else {
      handleBack();
    }
  };
  const NextPage = () => {
    UpdateNodeAndEdge();
    if (id) {
      navigate('/sequence/' + id + '/options');
    } else {
      handleNext();
    }
  };

  const addConditional = (sourceNodeId) => {
    const mainNodeid = uuidv4();
    const id1 = uuidv4();
    const id2 = uuidv4();

    setNodes((prevNodes) => {
      const sourceNode = prevNodes.find((node) => node.id === sourceNodeId);
      if (!sourceNode) {
        console.error(`Source node with id ${sourceNodeId} not found`);
        return prevNodes;
      }

      const mainNode = {
        id: mainNodeid,
        type: 'custom',
        data: {
          id: mainNodeid,
          label: `Conditional`,
          value: {
            waitingTime: 0,
            waitingTimeType: 'Minutes',
            trigger: 'Opened',
          },
        },
        position: { x: sourceNode.position.x, y: sourceNode.position.y + 100 },
      };

      const newNode1 = {
        id: id1,
        type: 'addNode',
        data: { id: id1, parent: 'conditional', label: true },
        position: {
          x: sourceNode.position.x - 100,
          y: sourceNode.position.y + 220,
        },
      };

      const newNode2 = {
        id: id2,
        type: 'addNode',
        data: { id: id2, parent: 'conditional', label: false },
        position: {
          x: sourceNode.position.x + 100,
          y: sourceNode.position.y + 220,
        },
      };

      const updatedNodes = prevNodes.map((node) => {
        if (node.id !== sourceNodeId) {
          if (node.position.x > sourceNode.position.x) {
            return {
              ...node,
              position: {
                ...node.position,
                x: node.position.x + 100,
              },
            };
          } else {
            return {
              ...node,
              position: {
                ...node.position,
                x: node.position.x - 100,
              },
            };
          }
        }
        return node;
      });
      return [...updatedNodes, mainNode, newNode1, newNode2];
    });

    setEdges((prevEdges) => {
      const newEdge = {
        id: `edge-${mainNodeid}`,
        source: sourceNodeId,
        target: mainNodeid,
        type: 'smoothstep',
        draggable: false,
      };
      const newEdge1 = {
        id: `edge-${id1}`,
        source: mainNodeid,
        target: id1,
        label: 'True',
        type: 'smoothstep',
        draggable: false,
      };

      const newEdge2 = {
        id: `edge-${id2}`,
        source: mainNodeid,
        target: id2,
        label: 'False',
        type: 'smoothstep',
        draggable: false,
      };

      return [...prevEdges, newEdge, newEdge1, newEdge2];
    });
    UpdateNodeAndEdge();
  };

  const addDelay = (sourceNodeId) => {
    const id = uuidv4();
    setNodes((prevNodes) => {
      const sourceNode = prevNodes.find((node) => node.id === sourceNodeId);
      if (!sourceNode) {
        console.error(`Source node with id ${sourceNodeId} not found`);
        return prevNodes;
      }

      const newNode = {
        id: id,
        type: 'custom',
        data: {
          id: id,
          parent: 'delay',
          label: `Delay`,
          value: { waitingTime: 0, waitingTimeType: 'Minutes' },
        },
        position: { x: sourceNode.position.x, y: sourceNode.position.y + 80 },
      };
      return [...prevNodes, newNode];
    });

    setEdges((prevEdges) => {
      const newEdge = {
        id: `edge-${id}`,
        source: sourceNodeId,
        target: id,
        label: '',
        type: 'smoothstep',
        draggable: false,
      };

      return [...prevEdges, newEdge];
    });
    UpdateNodeAndEdge();
  };

  const addNotification = (sourceNodeId) => {
    const id = uuidv4();

    setNodes((prevNodes) => {
      const sourceNode = prevNodes.find((node) => node.id === sourceNodeId);
      if (!sourceNode) {
        console.error(`Source node with id ${sourceNodeId} not found`);
        return prevNodes;
      }

      const newNode = {
        id: id,
        type: 'custom',
        data: { id: id, label: `Notification`, value: {} },
        position: { x: sourceNode.position.x, y: sourceNode.position.y + 100 },
      };

      return [...prevNodes, newNode];
    });

    setEdges((prevEdges) => {
      const newEdge = {
        id: `edge-${id}`,
        source: sourceNodeId,
        target: id,
        label: '',
        type: 'smoothstep',
        draggable: false,
      };

      return [...prevEdges, newEdge];
    });
    UpdateNodeAndEdge();
  };

  const saveValuetoNode = (value, id) => {
    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              value: value,
            },
          };
        }
        return node;
      });
    });
  };

  const deleteNode = (nodeId) => {
    const nodeToDelete = nodes.find((node) => node.id === nodeId);
    if (!nodeToDelete) {
      console.error(`Node with id ${nodeId} not found`);
      return;
    }

    const { label } = nodeToDelete.data;

    if (label === 'Conditional') {
      const deleteSubtree = (id) => {
        const childEdges = edges.filter((edge) => edge.source === id);
        childEdges.forEach((edge) => deleteSubtree(edge.target));
        setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
        setEdges((prevEdges) =>
          prevEdges.filter((edge) => edge.source !== id && edge.target !== id)
        );
      };
      deleteSubtree(nodeId);
    } else {
      const parentEdges = edges.filter((edge) => edge.target === nodeId);
      const childEdges = edges.filter((edge) => edge.source === nodeId);
      const parentId = parentEdges.length > 0 ? parentEdges[0].source : null;
      const childId = childEdges.length > 0 ? childEdges[0].target : null;
      setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
      setEdges((prevEdges) =>
        prevEdges.filter(
          (edge) => edge.source !== nodeId && edge.target !== nodeId
        )
      );

      if (parentId && childId) {
        setEdges((prevEdges) => [
          ...prevEdges,
          {
            id: uuidv4(),
            source: parentId,
            target: childId,
            type: 'smoothstep',
          },
        ]);
      }
    }
    UpdateNodeAndEdge();
  };

  const nodeTypes = useMemo(
    () => ({
      custom: (props) => (
        <CustomNode
          {...props}
          addDelay={addDelay}
          edges={edges}
          save={saveValuetoNode}
          deleteNode={deleteNode}
          addNotification={addNotification}
          addConditional={addConditional}
        />
      ),
      startNode: (props) => (
        <StartNode
          {...props}
          addDelay={addDelay}
          edges={edges}
          addNotification={addNotification}
          addConditional={addConditional}
        />
      ),
      addNode: (props) => (
        <AddNode
          {...props}
          addDelay={addDelay}
          edges={edges}
          addNotification={addNotification}
          addConditional={addConditional}
        />
      ),
    }),
    [edges]
  );

  useEffect(() => {
    dispatch(updateSequence({ nodes, edges }));
  }, [edges]);

  return (
    <Box
      style={{
        height: '75vh',
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box style={{ flex: 1 }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
        >
          <Background />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </Box>
      <Box
        mt={2}
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: -40,
          height: '60px',
          display: 'flex',
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f2f5',
        }}
      >
        <Button variant="outlined" size="large" onClick={PreviousPage}>
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={NextPage}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default SequenceBoard;
