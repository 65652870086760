import React, { useState } from 'react';
import { Box, Slide, Button, Stack, Typography } from '@mui/material';
import ListView from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import { ContainerStyle, MainDashboard } from 'components/Style';
import { WebBuilderTableColumns } from 'constants/appConstant';
import NoRecord from 'components/NoRecord';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import Editor from './editor/Editor';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const WebBuilder = () => {
  const [webBuilderData, setWebBuilderData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [searchTerms, setSearchTerms] = useState({});
  const [loading, setLoading] = useState({});
  const [initialLoading, setInitialLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showEditor, setShowEditor] = useState(false);

  const handleCreateModal = () => {
    setShowEditor(true);
  };

  const handleDeleteModel = (recordId) => {
    setDeleteModalOpen(true);
    setDeleteId(recordId);
  };

  const onClose = () => {
    setShowEditor(false);
    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    onClose();
  };

  if (showEditor) {
    return <Editor />
  }

  return (
    <MainDashboard>
      <Box sx={ContainerStyle.container}>
        <Stack direction="row" sx={ContainerStyle.topBar}>
          <Typography variant="h5" sx={ContainerStyle.heading}>
            Web Builder
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddToPhotosIcon />}
            onClick={handleCreateModal}
            size="large"
            sx={{ width: '150px', marginLeft: '16px', marginBottom: '6px' }}
          >
            Create
          </Button>
        </Stack>
        <Box sx={ContainerStyle.listView}>
          <ListView
            tableData={webBuilderData}
            rowData={WebBuilderTableColumns}
            handleDeleteModel={handleDeleteModel}
            initialLoading={initialLoading}
            loading={loading}
            sortingIndex={[
              'status',
              'websiteName',
              'websiteUrl',
              'createdTime',
              'modifiedTime',
            ]}
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            noRecordComponent={
              <NoRecord
                type="callback"
                moduleName="Web Builder"
                onAction={handleCreateModal}
              />
            }
          />
        </Box>
        <DeleteModal
          open={deleteModalOpen}
          close={onClose}
          placeholder="Web Builder"
          deleteFunction={handleDelete}
        />
      </Box>
    </MainDashboard>
  );
};

export default WebBuilder;