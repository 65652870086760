import React, { useEffect, useState } from 'react';
import NoRecord from 'components/NoRecord';
import CreateCustompromptPage from '../customPrompt/CreateCustomPrompt';
import { FetchAllCustomPrompt, DeleteCustomPrompt } from 'Api/Api';
import { editIcon } from 'constants/appImages';
import { trashIcon } from 'constants/appImages';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material';
import { InnerTextField } from 'components/InputFields';
import { Time } from '../customPrompt/constant';
import EditCustomPromptModal from '../customPrompt/EditCustomPromptModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { refresh } from 'aos';
import { Loading, SkeletonLoading } from 'components/Loading';
import { DeleteModal } from 'components/Modals';

export const CustomPrompt = ({ item, initialLoading, customPrompt, refreshCustom, refresh, setCustomPrompts }) => {

  const [openModal, setOpenModal] = useState(false);
  const [flag, setFlag] = useState(customPrompt !== null);
  const [error, setError] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);



  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };


  const handleCreateModal = () => {
    setOpenModal(true);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      let response = await DeleteCustomPrompt(customPrompt.id);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        setCustomPrompts(null);
        refresh()
        refreshCustom()
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    setFlag(customPrompt !== null)
  }, [customPrompt]);


  if (loading) {
    return (
      <Loading state={loading} />
    )
  }

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      {flag ?
        (
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1.5}
            >
              <Typography fontSize={'18px'} fontWeight={600} color={'black'}>
                Custom Prompt Details
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button onClick={handleOpen} sx={{ minWidth: 'auto', p: 0.5 }} disableRipple>
                  <img src={editIcon} alt="editIcon" width={23} height={23} />
                </Button>
                <Button onClick={() => setDeleteModalOpen(true)} sx={{ minWidth: 'auto', p: 0.5 }} disableRipple>
                  <img src={trashIcon} alt="deleteIcon" width={23} height={23} />
                </Button>
              </Box>
            </Box>
            <EditCustomPromptModal open={openModal}
              onClose={handleClose} itemId={customPrompt}
              refreshCustom={refreshCustom}
              refresh={refresh}
            />
            <DeleteModal
              open={deleteModalOpen}
              close={handleCloseDelete}
              placeholder="Custom Prompt"
              deleteFunction={() => handleDelete()}
            />
            <Box>
              <Box>
                <InnerTextField
                  required
                  margin="dense"
                  name="title"
                  label="Title"
                  value={customPrompt?.data?.title}
                  readOnly
                />
              </Box>

              <Box>
                <InnerTextField
                  required
                  name="description"
                  label="Description"
                  value={customPrompt?.data?.description}
                  readOnly
                />
              </Box>

              <Box marginY={1}>
                <Typography variant="black_4">Status</Typography>
                <Switch
                  name="status"
                  size="large"
                  checked={customPrompt?.status === 'Active'}
                  readOnly
                />
              </Box>
              <Typography sx={{ fontSize: '18px' }}
                variant="subtitle3">
                Upload Logo
              </Typography>
              <Box>
                {customPrompt?.iconImageURL && (
                  <img
                    src={customPrompt?.iconImageURL}
                    alt="Notification Icon"
                    width={200}
                    height={500}
                  />
                )}
              </Box>
              <Box my={1}>
                <Typography sx={{ fontSize: '18px' }} variant="black_p">
                  Allow Button
                </Typography>
              </Box>

              <Box>
                <InnerTextField
                  required
                  name="allowButtonText"
                  label="Text"
                  value={customPrompt?.data?.styles?.allowButtonText}
                  readOnly
                />
              </Box>

              <Box>
                <InnerTextField
                  required
                  name="allowButtonBackgroundColor"
                  label="Background Color"
                  colorSelection
                  value={customPrompt?.data?.styles?.allowButtonBackgroundColor}
                  readOnly
                />
              </Box>

              <Box>
                <InnerTextField
                  required
                  name="allowButtonTextColor"
                  label="Text Color"
                  colorSelection
                  value={customPrompt?.data?.styles?.allowButtonTextColor}
                  readOnly
                />
              </Box>

              <Box>
                <InnerTextField
                  required
                  label="Later Button Text"
                  name="laterButtonText"
                  value={customPrompt?.data?.styles?.laterButtonText}
                  readOnly
                />
              </Box>
              <Box my={1}>
                <Typography
                  sx={{ fontSize: '18px' }}
                  variant="subtitle3"
                >
                  Opt-in Timings
                </Typography>
              </Box>

              <Box>
                <InnerTextField
                  label="Mobile (In Sec)"
                  name="mobileTiming"
                  fullWidth
                  value={customPrompt?.data?.styles?.mobileTiming}
                  readOnly
                />
              </Box>

              <Box>
                <InnerTextField
                  label="Desktop (In Sec)"
                  name="desktopTiming"
                  fullWidth
                  options={Time}
                  value={customPrompt?.data?.styles?.desktopTiming}
                  readOnly
                />
              </Box>

              <Box>
                <InnerTextField
                  required
                  name="hidePromptFrequency"
                  label="Hide Prompt Frequency"
                  value={customPrompt?.data?.styles?.hidePromptFrequency}
                  readOnly
                />
              </Box>

              <Box>
                <InnerTextField
                  required
                  name="showPromptFrequency"
                  label="Show Prompt Frequency"
                  value={customPrompt?.data?.styles?.showPromptFrequency}
                  readOnly
                />
              </Box>
              <Box my={1}>
                <Typography
                  sx={{ fontSize: '18px' }}
                  variant="subtitle3"
                >
                  Position
                </Typography>
              </Box>

              <Box>
                <InnerTextField
                  label="Desktop"
                  name="desktopPosition"
                  fullWidth
                  value={customPrompt?.data?.styles?.desktopPosition}
                  readOnly
                />
              </Box>

              <Box>
                <InnerTextField
                  label="Mobile"
                  name="mobilePosition"
                  fullWidth
                  value={customPrompt?.data?.styles?.mobilePosition}
                  readOnly
                />
              </Box>
            </Box>
          </Box>
        ) : (
          !flag && (
            <>
              <NoRecord
                type="callback"
                moduleName="Custom Prompt"
                onAction={handleCreateModal}
              />
              <CreateCustompromptPage
                onClose={handleClose}
                open={openModal}
                item={item}
                refreshCustom={refreshCustom}
                refresh={refresh}
              />
            </>)
        )}
    </>
  );
};
