import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Skeleton } from '@mui/material';
import { FetchAllActionTracker } from 'Api/Api';
import { formatedDateTime } from 'utils/commonFunctions';
import { SkeletonLoading } from 'components/Loading';

const ActivityLogDetails = ({ logs, sx }) => {
  const truncate = (value) => {
    if (typeof value === 'object') {
      return null;
    }
    return value.length > 20 ? value.substring(0, 20) + '...' : value;
  };

  return (
    <Box sx={{ paddingLeft: '32px', flexGrow: 1, position: 'relative', ...sx }}>
      <Box
        sx={{
          position: 'absolute',
          left: '8px',
          top: 0,
          bottom: 0,
          width: '1.5px',
          backgroundColor: 'lightgrey',
          zIndex: 1,
        }}
      />
      {logs.length > 0 ? (
        logs.map((log) => {
          const { createdTime, action, preValue, postValue } = log;
          const { date: formattedDate, time: formattedTime } = formatedDateTime(createdTime);

          let logMessage = '';
          let updatedValues = {};

          if (action === 'Created') {
            logMessage = 'The Template was Created on';
          } else if (action === 'Deleted') {
            logMessage = 'The Template was Deleted on';
          } else if (action === 'Updated') {
            if (preValue && postValue) {
              const keyMapping = {
                templateName: 'Name',
                title: 'Title',
                pageURL: 'Url',
                message: 'Message',
                banner: 'bannerImageURL',
                icon: 'notificationIconURL',
              };

              updatedValues = Object.keys(postValue).reduce((acc, key) => {

                if (typeof preValue[key] === 'object' || typeof postValue[key] === 'object') {
                  return acc;
                } else if (key === 'banner') {
                  preValue[key] = preValue['bannerImageURL'];
                  postValue[key] = postValue['bannerImageURL'];
                } else if (key === 'icon') {
                  preValue[key] = preValue['notificationIconURL'];
                  postValue[key] = postValue['notificationIconURL'];
                } else if (postValue[key] != null && preValue[key] != null && postValue[key] !== preValue[key]) {
                  if (keyMapping[key]) {
                    acc[keyMapping[key]] = {
                      preValue: preValue[key],
                      postValue: postValue[key],
                    };
                  } else {
                    acc[key] = {
                      preValue: preValue[key],
                      postValue: postValue[key],
                    };
                  }
                }
                return acc;
              }, {});
              logMessage = 'The following were updated on Template';
            }



          }

          return (
            <Box
              key={log.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                padding: '16px',
                borderRadius: '8px',
                marginBottom: '16px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                position: 'relative',
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 'bold', color: 'black' }}
                >
                  {logMessage}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}
                >
                  {formattedDate} {formattedTime}
                </Typography>
              </Box>

              {action === 'Updated' && (
                <Box sx={{ marginTop: '8px' }}>
                  {Object.keys(updatedValues).length > 0 ? (
                    Object.entries(updatedValues).map(([field, values]) => (
                      <Typography
                        key={field}
                        variant="body2"
                        sx={{ color: 'grey' }}
                      >
                        {field}: {truncate(values.preValue)} →{' '}
                        {truncate(values.postValue)}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2" sx={{ color: 'grey' }}>
                      No changes detected.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          );
        })
      ) : (
        <Typography>No activity logs found.</Typography>
      )}







    </Box>
  );
};

const ActivityTemplate = ({ item }) => {
  const [activityLogs, setActivityLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadActivityLogs = async () => {
    if (!item?.id) {
      setError('Template ID is missing.');
      setLoading(false);
      return;
    }

    setLoading(true);
    const data = {
      recordId: item.id,
      moduleName: 'Template',
    };
    try {
      const response = await FetchAllActionTracker(data);

      if (response?.data?.status === true && response.data.data) {
        const logs = response.data.data;
        setActivityLogs(logs);
        setError(null);
      } else {
        setActivityLogs([]);
        setError('No activity logs found.');
      }
    } catch (error) {
      setError('Failed to load activity logs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (item?.id) {
      loadActivityLogs();
    } else {
      setError('Item ID is required to load activity logs.');
      setLoading(false);
    }
  }, [item]);

  if (loading) {
    return (
      <SkeletonLoading value={Object.keys(item).length}/>
    );
  }   if (error) return <Typography color="error">{error}</Typography>;

  const currentMonthYear = new Date().toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          marginBottom: '25px',
          marginLeft: '-13px',
          color: 'grey',
          marginTop: '16px',
          fontSize: '20px',
        }}
        gutterBottom
      >
        {currentMonthYear}
      </Typography>
      <ActivityLogDetails logs={activityLogs} sx={{ marginTop: '20px' }} />
    </Box>
  );
};

export default ActivityTemplate;
