import { Box, Stack, Typography } from '@mui/material';
import { FetchAllSubscribers } from 'Api/Api';
import ListView from 'components/ListView';
import NoRecord from 'components/NoRecord';
import { ContainerStyle, MainDashboard } from 'components/Style';
import { SubscribersTableColumns } from 'constants/appConstant';
import { useEffect, useState } from 'react';
import ViewSubscribersModal from './ViewSubscribersModal';

const Subscribers = () => {
  const [subscribersData, setSubscribersData] = useState([]);
  const [searchTerms, setSearchTerms] = useState({});
  const [loading, setLoading] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  async function fetch() {
    try {
      let response = await FetchAllSubscribers();
      if (response?.data?.status === true) {
        setSubscribersData(response?.data?.data);
      }
    } catch {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetch();
  }, []);

  return (
    <MainDashboard>
      <Box sx={ContainerStyle.container}>
        <Stack direction="row" sx={ContainerStyle.topBar}>
          <Typography variant="h5" sx={ContainerStyle.heading}>
            Subscribers
          </Typography>
        </Stack>
        <Box sx={ContainerStyle.listView}>
          <ListView
            tableData={subscribersData}
            rowData={SubscribersTableColumns}
            icons={['', 'delete']}
            sortingIndex={['status', 'createdTime', 'modifiedTime']}
            loading={loading}
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            noRecordComponent={
              <NoRecord
                type="callback"
                moduleName="Subscriber"
                // onAction={handleCreateModal}
              />
            }
          />
        </Box>

        <ViewSubscribersModal
          open={openModal}
          onCloseModal={() => {
            setOpenModal(false);
          }}
        />
      </Box>
    </MainDashboard>
  );
};

export default Subscribers;
