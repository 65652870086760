import { Box, Icon, Tabs, Menu, MenuItem } from '@mui/material';
import EnlargeModal, {
  CustomTab,
  CustomTabPanel,
  SvgSeparator,
  a11yProps,
} from 'components/EnlargeModal';
import React, { useState } from 'react';
import SubscriberDetails from './Enlarge/Subscribers';
import UnSubscriberDetails from './Enlarge/Unsubscribers';
import CampaignDetails from './Enlarge/Campaigns';
import ConditionsDetails from './Enlarge/Conditions';
import ActivityDetails from './Enlarge/Activity';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CampaignIconInActive from '../../../assets/Icons/SideBar/send-1.png';
import CampaignIconActive from '../../../assets/Icons/SideBar/send.png';
import ActivityIconInActive from '../../../assets/Icons/SideBar/activity-1.png';
import ActivityIconActive from '../../../assets/Icons/SideBar/activity.png';
import ConditionsIconInActive from '../../../assets/Icons/SideBar/git-fork-1.png';
import ConditionsIconActive from '../../../assets/Icons/SideBar/git-fork.png';
import UnsubscribersIconInActive from '../../../assets/Icons/SideBar/users-minus-1.png';
import UnsubscribersIconActive from '../../../assets/Icons/SideBar/users-minus.png';
import SubscribersIconInActive from '../../../assets/Icons/SideBar/users-plus-1.png';
import SubscribersIconActive from '../../../assets/Icons/SideBar/users-plus.png';

const EnlargeSegmentModal = ({ open, onCloseModal }) => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dynamicLabel, setDynamicLabel] = useState('Conditions');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleExpandClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (option) => {
    setAnchorEl(null);
    if (option) {
      setDynamicLabel(option);
    }
  };

  return (
    <EnlargeModal open={open} onCloseModal={onCloseModal} title={'Segment'}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ minHeight: '40px' }}
          >
            <CustomTab label="Subscribers" {...a11yProps(0)}
              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={value === 0 ? SubscribersIconActive : SubscribersIconInActive}
                    alt="Subscribers Icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
              segtext='true'
            />
            <SvgSeparator />
            <CustomTab label="Unsubscribers" {...a11yProps(2)}
              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={value === 2 ? UnsubscribersIconActive : UnsubscribersIconInActive}
                    alt="Unsubscribers Icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
              segtext='true'
            />
            <SvgSeparator />
            <CustomTab
              label="Campaigns"
              {...a11yProps(4)}
              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={value === 4 ? CampaignIconActive : CampaignIconInActive}
                    alt="Campaign Icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
              segtext='true'
            />
            <SvgSeparator />
            <CustomTab
              label={dynamicLabel}
              {...a11yProps(6)}
              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={
                      dynamicLabel === "Conditions"
                        ? value === 6 ? ConditionsIconActive : ConditionsIconInActive
                        : value === 6 ? ActivityIconActive : ActivityIconInActive
                    }
                    alt={`${dynamicLabel} Icon`}
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
              segtext='true'
            />
            <SvgSeparator />
            <ExpandMoreIcon
              style={{ marginTop: '21px', fontSize: '20px', cursor: 'pointer' }}
              onClick={handleExpandClick}
            />
          </Tabs>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleMenuClose(null)}
          >
            {dynamicLabel === "Conditions" ? (
              <MenuItem sx={{ color: 'black' }} onClick={() => handleMenuClose("Activity")}>Activity</MenuItem>
            ) : (
              <MenuItem sx={{ color: 'black' }} onClick={() => handleMenuClose("Conditions")}>Conditions</MenuItem>
            )}
          </Menu>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <SubscriberDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <UnSubscriberDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <CampaignDetails />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          {dynamicLabel === "Conditions" ? <ConditionsDetails /> : <ActivityDetails />}
        </CustomTabPanel>
      </Box>
    </EnlargeModal>
  );
};

export default EnlargeSegmentModal;
