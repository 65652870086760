import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import DateTimepicker from 'components/DateTimePicker';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateOptions } from '../../../redux/reducers/sequenceReducer';
import dayjs from 'dayjs';
import { CustomSelect } from 'components/CustomSelect';
import { SequenceScheuduler } from 'constants/appConstant';
import { getCurrentDateTimeLocal } from 'utils/commonFunctions';
import InfoIcon from '@mui/icons-material/Info';
import CustomTooltip from 'components/Tooltip';

const SequenceOption = ({ handleBack, handleNext }) => {
  const dispatch = useDispatch();
  const currentDate = dayjs();
  const navigate = useNavigate();
  let { id } = useParams();
  const options = useSelector((state) => state.sequence.options);

  const [formState, setFormState] = useState({
    schedule: options.schedule || 'now',
    frequency: options.frequency || '',
    triggerCampaign: options.triggerCampaign || false,
    subscribersInteractionNeeded: options.subscribersInteractionNeeded || false,
    frequencyDateTime: options.frequencyDateTime || currentDate,
  });
  const [errors, setErrors] = useState({});

  const handleScheduleChange = (event) => {
    const { value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      schedule: value,
    }));
  };

  const handleFrequencyChange = (event) => {
    const { value } = event.target;
    setFormState((prev) => ({
      ...prev,
      frequency: value,
      frequencyDateTime:
        value !== 'on_specific_date'
          ? getCurrentDateTimeLocal()
          : prev.frequencyDateTime,
    }));
    setErrors((prev) => ({ ...prev, frequency: !value.trim() }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const PreviousPage = () => {
    if (id) {
      navigate('/sequence/' + id + '/sequence');
    } else {
      handleBack();
    }
  };

  const NextPage = () => {
    dispatch(updateOptions({ formState }));
    if (id) {
      navigate('/sequence/' + id + '/review');
    } else {
      handleNext();
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '75vh',
        width: '100%',
      }}
    >
      <Box p={3} gap={4} sx={{ width: '85%' }}>
        <Typography p={0} variant="black_h4" sx={{ fontSize: 25 }}>
          Iteration
        </Typography>

        <Box my={1} ml={2} width={'100%'}>
          <RadioGroup
            aria-labelledby="schedule-radio-buttons-group-label"
            name="schedule"
            value={formState?.schedule}
            onChange={handleScheduleChange}
          >
            <Box>
              <FormControlLabel
                value="now"
                control={<Radio />}
                label="Send Now"
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontWeight: '500',
                    fontSize: '1.2rem',
                    color: 'black',
                  },
                }}
              />
            </Box>

            <Box
              mb={0}
              height={'60px'}
              display={'flex'}
              justifyContent={'left'}
              alignItems={'center'}
            >
              <Box width={'20%'}>
                <FormControlLabel
                  value="later"
                  control={<Radio />}
                  label="Schedule Later"
                  sx={{
                    '.MuiFormControlLabel-label': {
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      color: 'black',
                    },
                  }}
                />
              </Box>

              {formState?.schedule === 'later' && (
                <Box
                  width="60%"
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'end'}
                  gap={4}
                  ml={1}
                >
                  <FormControl sx={{ minWidth: '45%' }}>
                    <CustomSelect
                      label="Schedule"
                      margin="normal"
                      error={errors.frequency}
                      options={SequenceScheuduler}
                      value={formState?.frequency}
                      onChange={handleFrequencyChange}
                      helperText={errors.frequency ? 'Field required' : ''}
                    />
                  </FormControl>

                  <FormControl sx={{ minWidth: '50%' }}>
                    <DateTimepicker
                      onChangeval={(e) => {
                        setFormState({
                          ...formState,
                          frequencyDateTime: e,
                        });
                      }}
                    />
                  </FormControl>
                </Box>
              )}
            </Box>
          </RadioGroup>
        </Box>
      </Box>

      <Box px={1}>
        <Typography pl={2} variant="black_h4" sx={{ fontSize: 25 }}>
          Advance Options
        </Typography>

        <Box my={2} ml={4}>
          <FormGroup>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.triggerCampaign}
                    onChange={handleCheckboxChange}
                    name="triggerCampaign"
                  />
                }
                label="Trigger workflow based on the Subscriber Time zone"
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontWeight: '500',
                    fontSize: '1.2rem',
                    color: 'black',
                  },
                }}
              />
              <CustomTooltip
                title="This option triggers the workflow according to the subscriber's time zone."
                arrow
                placement="top"
                TransitionComponent={Zoom}
              >
                <IconButton size="small">
                  <InfoIcon fontSize="inherit" />
                </IconButton>
              </CustomTooltip>
            </Box>

            <Box display="flex" alignItems="center" mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.subscribersInteractionNeeded}
                    onChange={handleCheckboxChange}
                    name="subscribersInteractionNeeded"
                  />
                }
                label="Subscribers Interaction needed"
                sx={{
                  '.MuiFormControlLabel-label': {
                    fontWeight: '500',
                    fontSize: '1.2rem',
                    color: 'black',
                  },
                }}
              />
              <CustomTooltip
                title="This option requires interaction from subscribers to proceed."
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
              >
                <IconButton size="small">
                  <InfoIcon fontSize="inherit" />
                </IconButton>
              </CustomTooltip>
            </Box>
          </FormGroup>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: -40,
          height: '60px',
          display: 'flex',
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f0f2f5',
        }}
      >
        <Button variant="outlined" size="large" onClick={PreviousPage}>
          Cancel
        </Button>
        <Button variant="contained" size="large" onClick={NextPage}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default SequenceOption;
