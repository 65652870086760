import React, { useEffect, useRef, useState } from 'react';
import { Stepper, Step, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MainDashboard } from 'components/Style';
import SearchBar from 'components/SearchBar';
import SequenceSubscribers from './SequenceSubscriber';
import SequenceBoard from './SequenceBoard';
import SequenceOption from './SequenceOption';
import { resetState } from '../../../redux/reducers/sequenceReducer';
import { useDispatch } from 'react-redux';
import { protectedRoutes } from 'constants/appRoutes';
import SequenceReview from './SequenceReview';

const CreateSequence = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const labelRefs = useRef([]);
  const [labelWidths, setLabelWidths] = useState([]);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
      navigate(`/sequence/create/${steps[activeStep + 1].label.toLowerCase()}`);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      dispatch(resetState());
      navigate(protectedRoutes.sequence);
    } else {
      setActiveStep((prev) => prev - 1);
      navigate(`/sequence/create/${steps[activeStep - 1].label.toLowerCase()}`);
    }
  };

  const steps = [
    {
      label: 'Subscribers',
      component: (
        <SequenceSubscribers handleNext={handleNext} handleBack={handleBack} />
      ),
    },
    {
      label: 'Sequence',
      component: (
        <SequenceBoard handleNext={handleNext} handleBack={handleBack} />
      ),
    },
    {
      label: 'Options',
      component: (
        <SequenceOption handleNext={handleNext} handleBack={handleBack} />
      ),
    },
    {
      label: 'Review',
      component: (
        <SequenceReview handleNext={handleNext} handleBack={handleBack} />
      ),
    },
  ];

  useEffect(() => {
    const widths = labelRefs.current.map((ref) => ref?.offsetWidth || 0);
    setLabelWidths(widths);
  }, [steps]);

  return (
    <MainDashboard>
      <SearchBar placeHolder="New Sequence" button={false} />
      <br />
      <Box sx={{ width: '100%', minHeight: '85%' }}>
        <Box mt={-3} sx={{ width: '90%' }}>
          <Stepper activeStep={activeStep} alternativeLabel connector={null}>
            {steps.map((step, index) => (
              <Step key={step.label} sx={{ flex: 1, position: 'relative' }}>
                {/* Step Label */}
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontWeight: activeStep === index ? 'bold' : 'normal',
                    fontSize: '1rem',
                    backgroundColor:
                      activeStep === index ? '#07826F' : 'transparent',
                    color: activeStep === index ? '#fff' : '#919191',
                    padding: '8px 16px',
                    borderRadius: activeStep === index ? '4px' : 'none',
                    display: 'inline-block',
                    transition: 'all 0.3s ease',
                  }}
                >
                  {step.label}
                </Typography>

                {/* Fixed connector line across all steps */}
                {index < steps.length && (
                  <Box
                    sx={{
                      mt: 5,
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      width: '100%',
                      height: '2px',
                      backgroundColor: '#9e9e9e',
                      zIndex: 0,
                      transform: 'translateX(-50%)',
                    }}
                  />
                )}

                {index === activeStep && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '125%',
                      left: '19%',
                      transform: 'translateX(-50%)',
                      width: 20,
                      height: 20,
                      borderRadius: '50%',
                      backgroundColor: '#00b686',
                      zIndex: 2,
                    }}
                  />
                )}
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box sx={{ marginTop: 2 }}>{steps[activeStep].component}</Box>
      </Box>
    </MainDashboard>
  );
};

export default CreateSequence;
