import GroupIcon from "@mui/icons-material/Group";
import SegmentIcon from "@mui/icons-material/Segment";
import SendIcon from "@mui/icons-material/Send";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import CountryCard from "./Component/CountryCard";
import Graph from "./Component/Graph";
import WidgetCard from "./Component/WidgetCard";

export default function Dashboard() {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const username = userDetails?.userName;
  return (
    <Container
      maxWidth="xl"
      sx={{ pt: 2, backgroundColor: "rgb(251,251,251)" }}
    >
      <Typography sx={{ mb: 2, fontSize: "1.5rem" }}>
        Hi, {username} 👋
      </Typography>

      <Grid container spacing={2}>
        <Grid xs={12} sm={6} md={3}>
          <WidgetCard
            title="Connection"
            total={""}
            live=""
            color="success"
            icon={<SettingsInputComponentIcon sx={{ fontSize: "1.5rem" }} />}
          />
        </Grid>

        <Grid xs={12} sm={6} md={3}>
          <WidgetCard
            title="Subscriber"
            total={""}
            live=""
            color="info"
            icon={<GroupIcon sx={{ fontSize: "1.8rem" }} />}
          />
        </Grid>

        <Grid xs={12} sm={6} md={3}>
          <WidgetCard
            title="Segment"
            total={""}
            color="warning"
            live=""
            icon={<SegmentIcon sx={{ fontSize: "1.8rem" }} />}
          />
        </Grid>

        <Grid xs={12} sm={6} md={3}>
          <WidgetCard
            title="Campaign"
            total={""}
            live=""
            color="error"
            icon={<SendIcon sx={{ fontSize: "1.5rem" }} />}
          />
        </Grid>

        <Grid xs={12} md={6} lg={8}>
          <Graph
            title="Subscribers"
            subheader="0"
            chart={{
              labels: [
                "01/01/2003",
                "02/01/2003",
                "03/01/2003",
                "04/01/2003",
                "05/01/2003",
                "06/01/2003",
                "07/01/2003",
                "08/01/2003",
                "09/01/2003",
                "10/01/2003",
                "11/01/2003",
              ],
              series: [
                {
                  name: "Subscribers gain",
                  type: "column",
                  fill: "solid",
                
                },
              ],
            }}
          />
        </Grid>
        <Grid xs={12} md={6} lg={4}>
          <CountryCard data={null} />
        </Grid>
      </Grid>
    </Container>
  );
}
