import React, { useState } from 'react';
import { Box, Typography, Switch, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditTemplateModal from '../EditTemplateModal';
import { InnerTextField } from 'components/InputFields';
import { formatDateTime } from 'utils/commonFunctions';
import { DeleteTemplate } from 'Api/Api';
import toast from 'react-hot-toast';
import { editIcon, trashIcon } from 'constants/appImages';
import { DeleteModal } from 'components/Modals';
import { Loading } from 'components/Loading';

const Details = ({ item, refresh, onClose, refreshTemplate,setWelcomeTemplates }) => {

  const [openModal, setOpenModal] = useState(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await DeleteTemplate(item.id);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        if (refreshTemplate !== undefined) {
          setWelcomeTemplates(null)
          refreshTemplate()
        }
        refresh();
        setLoading(false);

      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Loading state={loading} />
    )
  }
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={'18px'} fontWeight={600} color={'black'}>
          Template Details
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button onClick={handleOpen} sx={{ minWidth: 'auto', p: 0.5 }} disableRipple>
            <img src={editIcon} alt="editIcon" width={23} height={23} />
          </Button>
          <Button onClick={() => setDeleteModalOpen(true)} sx={{ minWidth: 'auto', p: 0.5 }} disableRipple>
            <img src={trashIcon} alt="deleteIcon" width={23} height={23} />
          </Button>
        </Box>
      </Box>

      <EditTemplateModal
        open={openModal}
        onClose={handleClose}
        itemId={item}
        refresh={refresh}
        refreshTemplate={refreshTemplate}
      />

      <DeleteModal
        open={deleteModalOpen}
        close={handleCloseDelete}
        placeholder="Template"
        deleteFunction={() => handleDelete()}
      />

      <Box marginTop={1}>
        <InnerTextField
          label="Template Name"
          value={item?.templateName || ''}
          readOnly={true}
          fullWidth
        />
      </Box>

      <Box>
        <InnerTextField
          required
          name="title"
          label="Title"
          value={item?.title || ''}
          readOnly={true}
        />
      </Box>

      <Box>
        <InnerTextField
          label="Message"
          value={item?.message || ''}
          readOnly={true}
          fullWidth
        />
      </Box>

      <Box>
        <InnerTextField
          label="Redirecting URL"
          value={item?.pageURL || ''}
          readOnly={true}
          fullWidth
        />
      </Box>
      <br />
      <Typography variant="subtitle3">Upload Notification Icon </Typography>
      <Box>
        {item?.icon && (
          <img
            src={item.icon}
            alt="Notification Icon"
            width={200}
            height={500}
          />
        )}
      </Box>
      <br />
      <Typography variant="subtitle3">Upload Banner Image</Typography>
      <Box>
        {item?.banner && (
          <img src={item?.banner} alt="Your Image" width={200} height={500} />
        )}
      </Box>
      <Box>
        <Box>
          <Typography variant="black_4">Status</Typography>
          <Switch
            name="status"
            size="large"
            checked={item?.status === 'Active'}
            readOnly={true}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle2" my={1}>
          <strong>Created time : </strong>
          {formatDateTime(item?.createdTime)}
        </Typography>
        <Typography variant="subtitle2" my={1}>
          <strong> Modified time : </strong>{' '}
          {formatDateTime(item?.modifiedTime)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Details;
