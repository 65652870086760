import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export function Loading({ state }) {
  if (!state) {
    return;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "9999",
        position: "fixed",
        top: "0px",
        left: "0px",
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(2px)",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export const SkeletonLoading = ({value = 5}) => {
  return (
    <div >
      {Array.from({ length: value + 1 }).map((_, index) => (
        <Skeleton
          key={index}
          variant="text"
          width="100%"
          height={20}
          animation="wave"
          style={{ marginBottom: 10 }}
        />
      ))}
    </div>
  )

}