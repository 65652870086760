import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3D393D',
    color: 'white',
    fontSize: '0.875rem',
    boxShadow: theme.shadows[1],
    padding: '10px',
  },
}));

const CustomTooltip = ({
  children,
  title,
  arrow = true,
  placement = 'top',
  TransitionComponent = undefined,
  ...props
}) => {
  return (
    <BootstrapTooltip
      title={title}
      arrow={arrow}
      placement={placement}
      TransitionComponent={TransitionComponent}
      {...props}
    >
      {children}
    </BootstrapTooltip>
  );
};

export default CustomTooltip;
