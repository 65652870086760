import React, { useEffect, useState } from 'react';
import { Box, Slide } from '@mui/material';
import toast from 'react-hot-toast';
import ListView from 'components/ListView';
import { DeleteModal } from 'components/Modals';
import { ContainerStyle, MainDashboard } from 'components/Style';
import { SegmentTableColumns } from 'constants/appConstant';
import CreateSegmentModal from './CreateSegmentModal';
import EditSegmentModal from './EditSegmentModal';
import NoRecord from 'components/NoRecord';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { DeleteSegment, FetchAllSegment, UpdateSegment } from 'Api/Api';
import EnlargeSegmentModal from './EnlargeSegmentModal';
import { Button, Stack, Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Segment = () => {
  const [segmentData, setSegmentData] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [editId, setEditId] = useState();
  const [searchTerms, setSearchTerms] = useState({});
  const [isDetail, setIsDetail] = useState(false);
  const [loading, setLoading] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openEnlarge, setOpenEnlarge] = useState(false);

  const handleCreateModal = () => {
    setCreateModalOpen(true);
  };

  const handleDeleteModel = (recordId) => {
    setDeleteModalOpen(true);
    setDeleteId(recordId);
  };

  const handleEditModel = (recordId, isDetail) => {
    setEditModalOpen(true);
    setEditId(recordId);
    setIsDetail(isDetail);
  };

  const handleEnlargeView = (item) => {
    setOpenEnlarge(true);
  };

  const onClose = () => {
    setCreateModalOpen(false);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  async function fetch() {
    try {
      setInitialLoading(true);
      let response = await FetchAllSegment();
      if (response?.data?.status === true) {
        setSegmentData(response?.data?.data);
      }
    } catch (error) {
      toast.error('Failed to fetch segments.');
    } finally {
      setInitialLoading(false);
    }
  }

  async function deleteSegment(recordId) {
    try {
      const response = await DeleteSegment(recordId);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  async function updateStatus(item) {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [item.id]: true,
    }));
    try {
      const response = await UpdateSegment(item?.id, {
        id: item.id,
        segment_name: item.segment_name,
        status: item.status === 'Active' ? 'Inactive' : 'Active',
      });
      if (response?.data?.status === true) {
        fetch();
      }
    } catch (error) {
      toast.error('Failed to update status.');
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [item.id]: false,
      }));
    }
  }

  useEffect(() => {
    fetch();
    onClose();
  }, []);

  return (
    <MainDashboard>
      <Box sx={ContainerStyle.container}>
        <Stack direction="row" sx={ContainerStyle.topBar}>
          <Typography variant="h5" sx={ContainerStyle.heading}>
            Segments
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddToPhotosIcon />}
            onClick={handleCreateModal}
            size="large"
            sx={{ width: '150px', marginLeft: '16px', marginBottom: '6px' }}
          >
            Create
          </Button>
        </Stack>
        <Box sx={ContainerStyle.listView}>
          <ListView
            tableData={segmentData}
            rowData={SegmentTableColumns}
            handleDeleteModel={handleDeleteModel}
            handleEditModel={handleEditModel}
            updateStatus={updateStatus}
            initialLoading={initialLoading}
            loading={loading}
            sortingIndex={[
              'status',
              'segmentName',
              'createdTime',
              'modifiedTime',
            ]}
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            handleEnlargeModal={handleEnlargeView}
            noRecordComponent={
              <NoRecord
                type="callback"
                moduleName="Segment"
                onAction={handleCreateModal}
              />
            }
          />
        </Box>
        <EnlargeSegmentModal
          onCloseModal={() => setOpenEnlarge(false)}
          open={openEnlarge}
        />
        <CreateSegmentModal
          TransitionComponent={Transition}
          open={createModalOpen}
          onClose={onClose}
          refresh={fetch}
        />
        <EditSegmentModal
          TransitionComponent={Transition}
          open={editModalOpen}
          onClose={onClose}
          itemId={editId}
          isDetail={isDetail}
          refresh={fetch}
        />
        <DeleteModal
          open={deleteModalOpen}
          close={onClose}
          placeholder="Segment"
          deleteFunction={() => deleteSegment(deleteId)}
        />
      </Box>
    </MainDashboard>
  );
};

export default Segment;
