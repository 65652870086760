import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { protectedRoutes, unprotectedRoutes } from '../../constants/appRoutes';
import ProtectedScreen from '../protected/index';
import { Login } from './Login/LoginMain';
import MainLandingScreen from './LandingPage/MainLandingScreen';
import { useSelector } from 'react-redux';
import TermsAndCondition from './TermsAndCondition/TermsAndCondition';
import AboutCustomer from 'pages/protected/Users/AboutCustomer';

const UnprotectedScreen = () => {
  const isAuthUser = useSelector((state) => state.authReducer.isAuthUser);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const customerData = userDetails?.customerData;

  const isValidCustomerData =
    customerData &&
    customerData.industry &&
    customerData.business &&
    customerData.challenges &&
    customerData.solutionslookingfor;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLandingScreen />} />
        <Route
          path={unprotectedRoutes.termsAndCondition}
          element={<TermsAndCondition />}
        />
        <Route
          path={protectedRoutes.app}
          element={
            isAuthUser ? (
              <ProtectedScreen />
            ) : (
              <Navigate to={unprotectedRoutes.login} replace />
            )
          }
        />
        <Route
          path={unprotectedRoutes.login}
          element={
            isAuthUser ? (
              isValidCustomerData ? (
                <Navigate to={protectedRoutes.connections} replace />
              ) : (
                <Navigate to={protectedRoutes.questions} replace />
              )
            ) : (
              <Login />
            )
          }
        />

        <Route
          path={protectedRoutes.questions}
          element={
            isAuthUser ? (
              isValidCustomerData ? (
                <Navigate to={protectedRoutes.connections} replace />
              ) : (
                <AboutCustomer />
              )
            ) : (
              <Navigate to={unprotectedRoutes.login} replace />
            )
          }
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default UnprotectedScreen;
