import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Switch,
  Typography,
  IconButton,
  InputAdornment,
  List,
  Tab,
  ListItem,
  ListItemText,
} from '@mui/material';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import { Loading } from 'components/Loading';
import { SideDrawer } from 'components/SideDrawer';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const EditRealTimeCampaign = ({ open, onClose, itemId, refresh, isPreview, setIsPreview }) => {
  const [campaignDetails, setCampaignDetails] = useState(itemId || {});
  const [campaignName, setCampaignName] = useState('');
  const [messageText, setMessageText] = useState('');
  const [allowClose, setAllowClose] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [triggerFor, setTriggerFor] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [images, setImages] = useState({
    banner: itemId?.banner || '',
    icon: itemId?.icon || '',
  });
  const [filteredSegments] = useState([
    { id: 1, name: 'Segment 1' },
    { id: 2, name: 'Segment 2' },
  ]);

  useEffect(() => {
    if (itemId) {
      setCampaignDetails(itemId);
      setCampaignName(itemId.campaignName || '');
      setMessageText(itemId.messageText || '');
      setStatus(itemId.status === 'Active');
      setAllowClose(itemId.allowClose || false);
      setImages({
        banner: itemId.banner || '',
        icon: itemId.icon || '',
      });
    }
  }, [itemId]);

  const captureForm = () => {

  };

  const track = () => {

  };

  const handleSegmentSelect = (segment) => {
    setSearchTerm(segment.name);
    setShowDropdown(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleClear = () => {
    setSearchTerm('');
  };

  const handleToggle = (e) => {
    if (e.target.name === 'status') {
      const newStatus = campaignDetails.status === 'Active' ? 'Inactive' : 'Active';
      setCampaignDetails((prev) => ({ ...prev, status: newStatus }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    refresh();
    onClose();
    toast.success('Campaign updated successfully!');
  };

  return (
    <SideDrawer
      open={open}
      onClose={onClose}
      title="Realtime Notifications"
      handleSubmit={handleSubmit}
      EyeIcon={true}
      isPreview={isPreview}
      setIsPreview={setIsPreview}
    >
      {loading && <Loading state={loading} />}

      {!isPreview && (
        <Box>
          <Box width="90%">
            <TextField
              label="Conversion Title"
              variant="outlined"
              fullWidth
              required
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              margin="normal"
              InputProps={{
                style: {
                  borderRadius: '8px',
                  color: 'black'
                },
                inputProps: {
                  style: { color: 'black' },
                },
              }}
              InputLabelProps={{
                style: { color: 'black' },
                shrink: true,
                required: false
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%' }}>

              <TextField
                select
                label="Trigger for"
                variant="outlined"
                fullWidth
                required
                margin="normal"
                value={triggerFor}
                onChange={(e) => setTriggerFor(e.target.value)}
                InputProps={{
                  style: {
                    borderRadius: '8px',
                    color: 'black',
                  },
                }}
                InputLabelProps={{
                  style: { color: 'black' },
                  required: false,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'black',
                    },
                    '&:hover fieldset': {
                      borderColor: 'black',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                    '& .MuiSelect-select': {
                      color: 'black',
                    },
                  },
                }}
              >
                <MenuItem value="" style={{ color: 'black' }}>Select an option</MenuItem>
                <MenuItem value="Existing" style={{ color: 'black' }}>Existing</MenuItem>
                <MenuItem value="New" style={{ color: 'black' }}>New</MenuItem>
              </TextField>

              {triggerFor === 'Existing' && (
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>
                  <TextField
                    label="Select Segment"
                    variant="outlined"
                    required
                    margin="normal"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={handleInputFocus}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">

                          <IconButton onClick={handleClear} title="Clear">
                            <ClearIcon sx={{ color: 'black' }} />
                          </IconButton>

                          <IconButton onClick={handleInputFocus} title="Dropdown">
                            <ArrowDropDownIcon sx={{ color: 'black' }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: { borderRadius: '8px', color: 'black' },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: 'black' },
                      required: false,
                    }}
                    sx={{
                      width: '85%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                        '& .MuiInputBase-input': {
                          color: 'black',
                        },
                      },
                    }}
                  />

                  <IconButton
                    onClick={() => console.log('Add Segment')}
                    title="Add Segment"
                    sx={{
                      ml: 1,
                      mt: '13+px',
                      color: 'black',
                    }}
                  >
                    <ControlPointIcon sx={{ '& path': { fill: '#058270' }, fontSize: 35 }} />
                  </IconButton>

                  {showDropdown && (
                    <Box
                      sx={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        mt: 1,
                        position: 'absolute',
                        zIndex: 1000,
                        width: '85%',
                        bgcolor: 'white',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                        top: '100%',
                        left: 0,
                      }}
                    >
                      <List>
                        {filteredSegments.length > 0 ? (
                          filteredSegments.map((segment) => (
                            <ListItem
                              button
                              key={segment.id}
                              onClick={() => handleSegmentSelect(segment)}
                              sx={{
                                '& .MuiListItemText-primary': {
                                  color: 'black',
                                },
                              }}
                            >
                              <ListItemText primary={segment.name} />
                            </ListItem>
                          ))
                        ) : (
                          <ListItem>
                            <ListItemText primary="No segments found" sx={{ color: 'black' }} />
                          </ListItem>
                        )}
                      </List>
                    </Box>
                  )}
                </Box>
              )}
            </Box>

            <Box>
              <Typography variant="h9">Status</Typography>
              <Switch
                name="status"
                size="large"
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
              />
            </Box>
            <TextField
              select
              label="Capture Form"
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                style: { borderRadius: '8px', color: 'black' },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                  '& .MuiSelect-select': {
                    color: 'black',
                  },
                },
              }}
            >
              <MenuItem value="Zapier- Tally Form" sx={{ color: 'black' }}>
                Zapier- Tally Form
              </MenuItem>
              <MenuItem value="Zapier- Type Form" sx={{ color: 'black' }}>
                Zapier- Type Form
              </MenuItem>
              <MenuItem value="Zapier- Joho Form" sx={{ color: 'black' }}>
                Zapier- Joho Form
              </MenuItem>
              <MenuItem value="Zapier- Jot Form" sx={{ color: 'black' }}>
                Zapier- JotForm
              </MenuItem>
            </TextField>

            <TextField
              select
              label="Track"
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                style: { borderRadius: '8px', color: 'black' },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                  '& .MuiSelect-select': {
                    color: 'black',
                  },
                },
              }}
            >
              <MenuItem value="Newsletter Form" sx={{ color: 'black' }}>
                Newsletter Form
              </MenuItem>
              <MenuItem value="Testing Form" sx={{ color: 'black' }}>
                Testing Form
              </MenuItem>
              <MenuItem value="About us Form" sx={{ color: 'black' }}>
                About us Form
              </MenuItem>
            </TextField>
            <TextField
              select
              label="Where to Display (URL)"
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                style: { borderRadius: '8px', color: 'black' },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                  '& .MuiSelect-select': {
                    color: 'black',
                  },
                },
              }}
            >
              <MenuItem value="All Pages" sx={{ color: 'black' }}>
                All Pages
              </MenuItem>
            </TextField>

            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>

              <TextField
                label="URL"
                variant="outlined"
                fullWidth
                required
                margin="normal"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchTerm('')} title="Clear">
                        <ClearIcon sx={{ color: 'black' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: '8px', color: 'black' },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { color: 'black' },
                  required: false,
                }}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'black',
                    },
                    '&:hover fieldset': {
                      borderColor: 'black',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'black',
                    },
                    '& .MuiInputBase-input': {
                      color: 'black',
                    },
                  },
                }}
              />

              <IconButton
                onClick={() => console.log('Add URL')}
                title="Add URL"
                sx={{
                  ml: 1,
                  mt: '13px',
                  color: 'black',
                }}
              >
                <ControlPointIcon sx={{ '& path': { fill: '#058270' }, fontSize: 35 }} />
              </IconButton>
            </Box>
            <TextField
              label="Message Text"
              variant="outlined"
              fullWidth
              margin="normal"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              InputProps={{
                style: { borderRadius: '8px', color: 'black' },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
            />
            <TextField
              select
              label="Notification Position"
              fullWidth
              margin="normal"
              InputProps={{
                style: { borderRadius: '8px', color: 'black' },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
            >
              <MenuItem value="Left Corner" sx={{ color: 'black' }}>Left Corner</MenuItem>
              <MenuItem value="Right Corner" sx={{ color: 'black' }}>Right Corner</MenuItem>
            </TextField>
            <Box>
              <Typography variant="h9">Allow users to close the notifications</Typography>
              <Switch
                name="Allow users to close the notifications"
                size="large"
                checked={allowClose}
                onChange={(e) => setAllowClose(e.target.checked)}
              />
            </Box>
            <TextField
              select
              label="Redirecting Notification"
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                style: { borderRadius: '8px', color: 'black' },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
            ></TextField>

            <TextField
              select
              label="Capture the events from past"
              fullWidth
              margin="normal"
              InputProps={{
                style: { borderRadius: '8px', color: 'black' },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
            >
              <MenuItem value="12 Hours" sx={{ color: 'black' }}>12 Hours</MenuItem>
              <MenuItem value="24 Hours" sx={{ color: 'black' }}>24 Hours</MenuItem>
              <MenuItem value="48 Hours" sx={{ color: 'black' }}>48 Hours</MenuItem>
            </TextField>
            <TextField
              select
              label="Display Duration"
              fullWidth
              margin="normal"
              InputProps={{
                style: { borderRadius: '8px', color: 'black' },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black',
                  },
                  '&:hover fieldset': {
                    borderColor: 'black',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                },
              }}
            >
              <MenuItem value="3 sec" sx={{ color: 'black' }}>3 sec</MenuItem>
            </TextField>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '24px',
              }}
            >
            </div>
          </Box>
        </Box>
      )}
      {isPreview && (
        <Box sx={{ height: '100%', width: '100%' }}>
          <Box
            sx={{
              height: '60px',
              display: 'flex',
              p: 1,
              alignItems: 'center',
              borderBottom: '1.8px solid rgba(241, 242, 247, 1)',
            }}
          >
            <Typography
              variant="black_h4"
              style={{
                fontFamily: 'Outfit',
                fontSize: '20px',
                fontWeight: 400,
                textAlign: 'left',
              }}
            >
              Preview
            </Typography>
          </Box>
          <Box sx={{ height: 'calc(100% - 60px)' }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="device preview tabs">
                  <Tab label="Desktop" value="1" />
                  <Tab label="Tablet" value="2" />
                  <Tab label="Mobile" value="3" />
                </TabList>
              </Box>
              <TabPanel sx={{ height: '60vh' }} value="1">
                <Box
                  sx={{
                    mx: 'auto',
                    width: '90%',
                    borderRadius: 4,
                    height: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    border: '1.8px solid rgba(0,0,0,.12)',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '40px',
                      borderBottom: '1.8px solid rgba(0,0,0,.12)',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      pl: 2,
                    }}
                  >
                    {['#EC6A5E', '#F5BF4F', '#61C554'].map((color, index) => (
                      <Box
                        key={index}
                        sx={{
                          height: 15,
                          width: 15,
                          borderRadius: '50%',
                          backgroundColor: color,
                        }}
                      />
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      p: 2,
                      height: '100%',
                      overflowY: 'scroll',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                    >
                      {campaignName}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {messageText}
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel sx={{ height: '60vh' }} value="2">
                <Box
                  sx={{
                    mx: 'auto',
                    width: '90%',
                    borderRadius: 4,
                    height: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    border: '1.8px solid rgba(0,0,0,.12)',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '40px',
                      borderBottom: '1.8px solid rgba(0,0,0,.12)',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      pl: 2,
                    }}
                  >
                    {['#EC6A5E', '#F5BF4F', '#61C554'].map((color, index) => (
                      <Box
                        key={index}
                        sx={{
                          height: 15,
                          width: 15,
                          borderRadius: '50%',
                          backgroundColor: color,
                        }}
                      />
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      p: 2,
                      height: '100%',
                      overflowY: 'scroll',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                    >
                      {campaignName}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {messageText}
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel sx={{ height: '60vh' }} value="3">
                <Box
                  sx={{
                    mx: 'auto',
                    width: '90%',
                    borderRadius: 4,
                    height: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    border: '1.8px solid rgba(0,0,0,.12)',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '40px',
                      borderBottom: '1.8px solid rgba(0,0,0,.12)',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      pl: 2,
                    }}
                  >
                    {['#EC6A5E', '#F5BF4F', '#61C554'].map((color, index) => (
                      <Box
                        key={index}
                        sx={{
                          height: 15,
                          width: 15,
                          borderRadius: '50%',
                          backgroundColor: color,
                        }}
                      />
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      p: 2,
                      height: '100%',
                      overflowY: 'scroll',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                    >
                      {campaignName}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {messageText}
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      )}
    </SideDrawer>
  );
};

export default EditRealTimeCampaign;
