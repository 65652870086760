import React from 'react';
import connectionActive from '../Icons/SideBar/Connections w.png';
import connectionInactive from '../Icons/SideBar/Connections.png';
import campaignActive from '../Icons/SideBar/Campaign w.png';
import campaignInactive from '../Icons/SideBar/Campaign.png';
import templateActive from '../Icons/SideBar/Templates w.png';
import templateInactive from '../Icons/SideBar/Templates.png';
import webPushActive from '../Icons/SideBar/Webpush w.png';
import webPushInactive from '../Icons/SideBar/Webpush.png';
import analyticsActive from '../Icons/SideBar/Analytics w.png';
import analyticsInactive from '../Icons/SideBar/Anlytics.png';
import automationActive from '../Icons/SideBar/Automation w.png';
import automationInactive from '../Icons/SideBar/Automation.png';
import dashboardActive from '../Icons/SideBar/Dashboard w.png';
import dashboardInactive from '../Icons/SideBar/Dashboard.png';
import realtimeActive from '../Icons/SideBar/Realtime w.png';
import realtimeInactive from '../Icons/SideBar/Realtime.png';
import segmentActive from '../Icons/SideBar/Segment w.png';
import segmentInactive from '../Icons/SideBar/Segment.png';
import subscriberActive from '../Icons/SideBar/Subscribers w.png';
import subscriberInactive from '../Icons/SideBar/Subscribers.png';
import feedsActive from '../Icons/SideBar/Feeds w.png';
import feedsInactive from '../Icons/SideBar/Feeds.png';
import sequenceActive from '../Icons/SideBar/Sequence w.png';
import sequenceInactive from '../Icons/SideBar/Sequence.png';

export const webPushIconActive = () => {
  return (
    <img
      src={webPushActive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};

export const webPushIconInactive = () => {
  return (
    <img
      src={webPushInactive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};

export const dashboardIconActive = () => {
  return (
    <img
      src={dashboardActive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};
export const dashboardIconInactive = () => {
  return (
    <img
      src={dashboardInactive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};

export const campaignIconActive = () => {
  return (
    <img
      src={campaignActive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};
export const campaignIconInactive = () => {
  return (
    <img
      src={campaignInactive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};

export const templateIconActive = () => {
  return (
    <img
      src={templateActive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};
export const templateIconInactive = () => {
  return (
    <img
      src={templateInactive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};

export const segmentIconActive = () => {
  return (
    <img
      src={segmentActive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};
export const segmentIconInactive = () => {
  return (
    <img
      src={segmentInactive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};

export const sequenceIconActive = () => {
  return (
    <img
      src={sequenceActive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};
export const sequenceIconInactive = () => {
  return (
    <img
      src={sequenceInactive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};

export const automationIconActive = () => {
  return (
    <img
      src={automationActive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};
export const automationIconInactive = () => {
  return (
    <img
      src={automationInactive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};

export const realTimeIconActive = () => {
  return (
    <img
      src={realtimeActive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};
export const realTimeIconInactive = () => {
  return (
    <img
      src={realtimeInactive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};

export const connectionIconActive = () => {
  return (
    <img
      src={connectionActive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};
export const connectionIconInactive = () => {
  return (
    <img
      src={connectionInactive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};

export const subscriberIconActive = () => {
  return (
    <img
      src={subscriberActive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};

export const subscriberIconInactive = () => {
  return (
    <img
      src={subscriberInactive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};

export const analyticsIconActive = () => {
  return (
    <img
      src={analyticsActive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};

export const analyticsIconInactive = () => {
  return (
    <img
      src={analyticsInactive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};

export const feedsIconActive = () => {
  return (
    <img
      src={feedsActive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};

export const feedsIconInactive = () => {
  return (
    <img
      src={feedsInactive}
      alt="icon"
      style={{ width: '20px', height: '20px' }}
    />
  );
};

export const Shopify = () => {
  return (
    <img
      src={connectionActive}
      alt="icon"
      style={{ width: '24px', height: '24px' }}
    />
  );
};
