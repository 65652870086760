import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, Skeleton } from '@mui/material';
import { FetchAllActionTracker } from 'Api/Api';
import { formatedDateTime } from 'utils/commonFunctions';
import { SkeletonLoading } from 'components/Loading';

const ActivityLogDetails = ({ logs, sx, logsOfWelcome, logsOfPrompt }) => {
  const truncate = (value) => {
    if (typeof value === 'object') {
      return null;
    }
    // return value?.length > 20 ? value.substring(0, 20) + '...' : value;
    return value;
  };

  const sortByTime = (logArray) => {
    return logArray.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime));
  };

  const renderLogs = (combinedLogs) => {
    const sortedLogs = sortByTime(combinedLogs);

    return sortedLogs.length > 0 ? (
      sortedLogs.map((log) => {
        const { createdTime, action, preValue, postValue } = log;
        const { date: formattedDate, time: formattedTime } = formatedDateTime(createdTime);

        let logMessage = '';
        let updatedValues = [];

        if (action === 'Created') {
          logMessage = `The ${log.module} was Created on`;
        } else if (action === 'Deleted') {
          logMessage = `The ${log.module} was deleted on`;
        } else if (action === 'Updated') {
          if (preValue && postValue) {
            const keyMapping = {
              connectionImageBase64: 'Image updated',
              banner: 'Banner updated',
              icon: 'Icon updated',
            };

            updatedValues = Object.keys(postValue).reduce((acc, key) => {

              // Skip if preValue or postValue are objects
              if (typeof preValue[key] === 'object' || typeof postValue[key] === 'object') {
                return acc;
              }

              if (keyMapping[key]) {
                acc[keyMapping[key]] = { message: keyMapping[key] };
              }

              else if (key === 'deleted' && (postValue[key] == "false" || preValue[key] == false)) {

                acc[key] = {
                  preValue: preValue[key],
                  postValue: postValue[key],
                };
                return acc;
              }
              else if (postValue[key] != null && preValue[key] != null && postValue[key] !== preValue[key]) {
                if (keyMapping[key]) {
                  acc[keyMapping[key]] = {
                    preValue: preValue[key],
                    postValue: postValue[key],
                  };
                } else {
                  acc[key] = {
                    preValue: preValue[key],
                    postValue: postValue[key],
                  };
                }
              }
              return acc;
            }, {});

            if (Object.keys(updatedValues).length > 0) {
              logMessage = `${log.module} updated on `;
            }
          }
        }

        if (action === 'Updated' && Object.keys(updatedValues).length === 0) {
          return null;
        }

        return (
          <Box
            key={log.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'white',
              padding: '16px',
              borderRadius: '8px',
              marginBottom: '16px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              position: 'relative',
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'black' }}>
                {logMessage}
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}
              >
                {formattedDate} {formattedTime}
              </Typography>
            </Box>

            {action === 'Updated' && (
              <Box sx={{ marginTop: '8px' }}>
                {Object.entries(updatedValues).map(([field, values]) => (
                  <Typography key={field} variant="body2" sx={{ color: 'grey' }}>
                    {values.message
                      ? values.message
                      : `${field}: ${truncate(values.preValue)} → ${truncate(values.postValue)}`}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        );
      })
    ) : (
      <></>
    );
  };

  const combinedLogs = [...logs, ...logsOfWelcome, ...logsOfPrompt];

  return (
    <Box sx={{ paddingLeft: '32px', flexGrow: 1, position: 'relative', ...sx }}>
      <Box
        sx={{
          position: 'absolute',
          left: '8px',
          top: 0,
          bottom: 0,
          width: '1.5px',
          backgroundColor: 'lightgrey',
          zIndex: 1,
        }}
      />

      {renderLogs(combinedLogs)}
    </Box>
  );
};


const ActivityTemplate = ({ item }) => {
  const [activityLogs, setActivityLogs] = useState([]);
  const [activityLogsOfTemplate, setActivityLogsOfTemplate] = useState([]);
  const [activityLogsOfPrompt, setActivityLogsOfPrompt] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadActivityLogs = async () => {
    if (!item?.id) {
      // setError('Connection ID is missing.');
      setLoading(false);
      return;
    }

    setLoading(true);
    const data = {
      recordId: item.id,
      moduleName: 'Connection',
    };

    try {
      const response = await FetchAllActionTracker(data);

      if (response?.data?.status === true && response.data.data) {
        const logs = response.data.data;
        setActivityLogs(logs);
        setError(null);
      } else {
        setActivityLogs([]);
        setError('No activity logs found.');
      }
    } catch (error) {
      setError('Failed to load activity logs');
    } finally {
      setLoading(false);
    }
  };

  const loadActivityLogsOfPrompt = async () => {
    if (!item?.prompt?.id) {
      // setError('Custom Prompt ID is missing.');
      setLoading(false);
      return;
    }

    setLoading(true);
    const data = {
      recordId: item?.prompt?.id,
      moduleName: 'CustomPrompt',
    };

    try {
      const response = await FetchAllActionTracker(data);

      if (response?.data?.status === true && response.data.data) {
        const logs = response.data.data;
        setActivityLogsOfPrompt(logs);
        setError(null);
      } else {
        setActivityLogsOfPrompt([]);
        // setError('No activity logs found.');
      }
    } catch (error) {
      setError('Failed to load activity logs');
    } finally {
      setLoading(false);
    }
  };
  const loadActivityLogsOfTemplate = async () => {
    const selectedTemplate = item?.templates?.find((template) => template.welcomeTemplate === true);

    if (!selectedTemplate?.id) {
      // setError('Welcome Template ID is missing.');
      setLoading(false);
      return;
    }
    setLoading(true);
    const data = {
      recordId: selectedTemplate?.id,
      moduleName: 'Template',
    };

    try {
      const response = await FetchAllActionTracker(data);
      if (response?.data?.status === true && response.data.data) {
        const logs = response.data.data;
        setActivityLogsOfTemplate(logs);
        setError(null);
      } else {
        setActivityLogsOfTemplate([]);
        // setError('No activity logs found.');
      }
    } catch (error) {
      setError('Failed to load activity logs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (item?.id) {
      loadActivityLogs();
      loadActivityLogsOfPrompt();
      loadActivityLogsOfTemplate();
    } else {
      setError('Item ID is required to load activity logs.');
      setLoading(false);
    }
  }, [item]);
  
  if (loading) {
    return (
      <SkeletonLoading value={Object.keys(item).length} />
    );
  } if (error) return <Typography color="error">{error}</Typography>;

  const currentMonthYear = new Date().toLocaleString('default', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          marginBottom: '25px',
          marginLeft: '-13px',
          color: 'grey',
          marginTop: '16px',
          fontSize: '20px',
        }}
        gutterBottom
      >
        {currentMonthYear}
      </Typography>
      <ActivityLogDetails logs={activityLogs}
        logsOfWelcome={activityLogsOfTemplate}
        logsOfPrompt={activityLogsOfPrompt} sx={{ marginTop: '20px' }} />
    </Box>
  );
};

export default ActivityTemplate;
