import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa6';
import { FaMinus } from 'react-icons/fa6';
import { useState } from 'react';
import { CustomSelect } from 'components/CustomSelect';
import { ConditionDrawerArray } from 'constants/appConstant';

const AddDelay = ({ deletenode, data, close, open, saveData }) => {
  const [waitingTime, setWaitingTime] = useState(data.waitingTime);
  const [waitingTimeType, setWaitingTimeType] = useState(data.waitingTimeType);

  const handleWaitingTimeChange = (increment) => {
    setWaitingTime((prev) => Math.min(60, Math.max(0, prev + increment)));
  };

  const handleWaitingTimeTypeChange = (event) => {
    setWaitingTimeType(event.target.value);
  };

  const handleSave = () => {
    saveData({ waitingTime: waitingTime, waitingTimeType: waitingTimeType });
  };

  return (
    <Drawer anchor={'right'} onClose={close} open={open}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 400,
          p: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            marginTop: 2,
          }}
        >
          <Typography
            mb={2}
            variant="black_h4"
            sx={{ fontSize: 25 }}
            gutterBottom
          >
            Add Delay
          </Typography>

          <Button
            sx={{
              padding: 0.2,
              minWidth: 0,
              pointerEvents: 'all',
              marginTop: 0.6,
            }}
            variant="text"
            onClick={deletenode}
          >
            <MdDelete color="red" size={25} />
          </Button>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography mb={3} variant="black" sx={{ fontSize: 20 }}>
            Waiting Time
          </Typography>

          <Box
            sx={{
              width: '85%',
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              pl: 1,
            }}
          >
            <Box
              ml={1}
              sx={{
                width: '35%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={() => handleWaitingTimeChange(-1)}
                sx={{
                  p: 1,
                  minWidth: 0,
                  borderRadius: '50%',
                  backgroundColor: 'transparent',
                  color: 'black',
                  border: '1px solid gray',
                }}
                variant="contained"
              >
                <FaMinus />
              </Button>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: 3,
                  fontSize: 20,
                }}
              >
                {waitingTime}
              </Box>

              <Button
                onClick={() => handleWaitingTimeChange(1)}
                sx={{
                  p: 1,
                  minWidth: 0,
                  borderRadius: '50%',
                  backgroundColor: 'transparent',
                  color: 'black',
                  border: '1px solid gray',
                }}
                variant="contained"
              >
                <FaPlus />
              </Button>
            </Box>

            <FormControl sx={{ minWidth: '50%' }}>
              <CustomSelect
                name="waitingTimeType"
                value={waitingTimeType}
                onChange={handleWaitingTimeTypeChange}
                options={ConditionDrawerArray}
                sx={{ color: 'black' }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          alignItems: 'center',
          position: 'absolute',
          bottom: 0,
          borderTop: '1px solid gray',
          width: '100%',
          padding: 1,
        }}
      >
        <Button
          variant="text"
          sx={{ border: '1px solid #033A32', color: '#033A32', fontSize: 15 }}
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="contained"
          sx={{ bgcolor: '#07826F', color: 'white', fontSize: 15 }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Drawer>
  );
};

export default AddDelay;
