import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  List,
  Typography,
  TextField,
  Paper,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { GetAllKeys, TestNotification } from 'Api/Api';
import { useNavigate } from 'react-router-dom';
import { protectedRoutes } from 'constants/appRoutes';
import toast from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DownloadRounded } from '@mui/icons-material';

const CodeInjection = ({
  apiKey,
  secretKey,
  connectionId,
  setSecretKey,
  setApiKey,
}) => {
  const navigate = useNavigate();
  const textFieldRef = useRef(null);

  async function copyPublicKey() {
    const response = await GetAllKeys();
    if (response.status === 200) {
      navigator.clipboard.writeText(response.data.public_key);
      toast.success('copied successfully');
    } else {
      toast.error(response.data.message);
    }
  }

  async function sendTestPushNotification() {
    try {
      const testPayload = {
        isTestNotification: true,
        subscriptionID: localStorage.getItem('letsNotifySubId'),
      };
      const response = await TestNotification(testPayload);
      if (response?.data?.status === true) {
        toast.success(response?.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  const handleCompleteSetup = () => {
    setApiKey('');
    setSecretKey('');
    navigate(protectedRoutes.connections);
  };

  const handleCopyClick = () => {
    if (textFieldRef.current) {
      const codeToCopy = textFieldRef.current.value;
      navigator.clipboard
        .writeText(codeToCopy)
        .then(() => {
          toast.success('Copied to clipboard!');
        })
        .catch((err) => {
          toast.error('Failed to copy: ', err);
          console.error('Failed to copy: ', err);
        });
    }
  };

  const downloadKeys = () => {
    const fileContent = `importScripts("https://cdn.letsnotify.co.in/sw")`;
    const blob = new Blob([fileContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'script.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Paper elevation={0} sx={{ mb: 3, width: '100%', borderRadius: 1 }}>
        <Typography variant="h6" sx={{ mb: 0 }}>
          Integration with HTTP(s) websites/blogs
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 2 }}>
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            <ListItemText
              primary="Download this LetsNotifySW.js file in a root directory"
              primaryTypographyProps={{ variant: '' }}
            />
          </ListItem>
          <Button sx={{ padding: 0, alignItems: 'center' }}>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ mr: 1 }}>
                LetsNotifySW.js
              </Typography>
              <DownloadRounded
                variant="outlined"
                sx={{ cursor: 'pointer' }}
                onClick={downloadKeys}
              />
            </Box>
          </Button>
          <ListItem sx={{ display: 'list-item', padding: 0 }}>
            <ListItemText
              primary="Copy the code below and paste it into your index file. Make sure to verify the path to the LetsNotifySW.js file"
              primaryTypographyProps={{ variant: '' }}
            />
          </ListItem>
        </List>

        <TextField
          multiline
          rows={14}
          fullWidth
          variant="outlined"
          defaultValue={`<script src="https://cdn.letsnotify.co.in/web/push/v1"></script>
<script>
  const sdk = new LetsNotifySDK();
  const pushSDK = sdk.pushSdk();
  pushSDK.init({
    connectionId: "${connectionId ? connectionId : 'your-connection-id'}",
    serviceWorkerPath: "LetsNotifySW.js",
    apiKey: "${apiKey ? apiKey : 'your-api-key'}",
    secretKey: "${secretKey ? secretKey : 'your-secret-key'}"
  });
</script>`}
          InputProps={{
            readOnly: true,
            style: {
              fontFamily: 'monospace',
              userSelect: 'none',
              position: 'relative',
              height: 'auto',
              overflow: 'hidden',
              wordWrap: 'break-word',
            },
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ position: 'absolute', top: '24px', right: '-5px' }}
              >
                <IconButton onClick={handleCopyClick}>
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          style={{
            margin: '0 auto',
            maxWidth: '100%',
            boxSizing: 'border-box',
          }}
          inputRef={textFieldRef}
          onFocus={(e) => e.target.setSelectionRange(0, 0)}
        />

        <Box sx={{ my: 1.5, px: 1, py: 0.5 }}>
          You can now complete the setup. If you'd like to validate it, click
          the 'Test Notification' button. Afterward, visit your website, where
          you'll see a permission popup from the browser. Once you allow it, you
          should receive a test push notification.
        </Box>

        <Box
          mt={1}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: 5,
            border: '2px solid redd',
          }}
        >
          <Button variant="contained" onClick={handleCompleteSetup}>
            Complete setup
          </Button>
          <Button variant="outlined" onClick={sendTestPushNotification}>
            Test notification
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default CodeInjection;
