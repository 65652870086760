import CopyrightIcon from '@mui/icons-material/Copyright';
import { Box, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import DiscordIcon from 'assets/Images/Landing/Footer/discord.svg';
import EmailIcon from 'assets/Images/Landing/Footer/email.svg';
import LinkedinIcon from 'assets/Images/Landing/Footer/linkedin.svg';
import XIcon from 'assets/Images/Landing/Footer/prime_twitter.svg';
import RedditIcon from 'assets/Images/Landing/Footer/reddit.svg';
import EmailLink from 'components/Email';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

const Footer = forwardRef(({ classname }, ref) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  return (
      <Box sx={{ height: { xs: '100vh', lg: '40vh' } }} className="footer-main">
        <Box
          sx={{
            padding: { xs: 2, lg: 0 },
            display: 'flex',
            justifyContent: 'center',
            alignItems: { xs: 'flex-start', lg: 'center' },
            flexDirection: 'column',
            position: 'relative',
            height: {xs: '80%', lg: '80%'}
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
            className={isMedium ? "" : "bgtext"}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{xs: 5, md: 0}}
              alignItems={{xs: 'flex-start', md: 'center'}}
              justifyContent={{xs: 'center', md: 'space-between'}}
              zIndex="10"
              mt={3}
              width={{xs: '100%', sm: '80%', md: '70%'}}
              height={'100%'}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: '16px',
                    textTransform: 'uppercase',
                    color: '#fff',
                    mb: 2,
                  }}
                >
                  Follow us
                </Typography>
                <Stack direction={'row'} spacing={3}>
                  <IconButton sx={{ backgroundColor: '#fff'}} onClick={() => {window.open('https://www.linkedin.com/company/qwiknotify/')}}>
                    <img src={LinkedinIcon} alt="" width={24} height={24} />
                  </IconButton>
                  <IconButton sx={{ backgroundColor: '#fff' }} onClick={() => {window.open('https://twitter.com/qwiknotify_')}}>
                    <img src={XIcon} alt="" width={24} height={24} />
                  </IconButton>
                  <IconButton sx={{ backgroundColor: '#fff' }} onClick={() => {window.open('https://www.reddit.com/user/qwiknotify_/')}}>
                    <img src={RedditIcon} alt="" width={24} height={24} />
                  </IconButton>
                </Stack>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: '16px',
                    textTransform: 'uppercase',
                    color: '#fff',
                    mb: 2,
                  }}
                >
                  Our Community
                </Typography>
                <IconButton sx={{ backgroundColor: '#fff' }} onClick={() => {window.open('https://discord.com/channels/1227529106003070996/1227529106003070998')}}>
                  <img src={DiscordIcon} alt="" width={24} height={24} />
                </IconButton>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: '16px',
                    textTransform: 'uppercase',
                    color: '#fff',
                    mb: 2,
                  }}
                >
                  Contact us
                </Typography>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <IconButton sx={{ backgroundColor: '#fff' }} onClick={() => {window.open('mailto:support@letsnotify.co.in')}}>
                    <img src={EmailIcon} alt="" width={24} height={24} />
                  </IconButton>
                  <Typography sx={{ fontSize: '20px', color: '#fff', my: 2 }}>
                    <EmailLink />
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            height: { xs: 'calc(100vh - 85vh)', lg: '20%' },
            position: 'relative',
          }}
        >
          <Box
            display={'flex'}
            gap={0}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              height: { xs: '100%', lg: '3rem' },
              borderTop: '1px solid #FFFFFF66',
              width: '100%',
              p: 2,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          >
            <CopyrightIcon sx={{ color: '#fff' }} />
            <Typography sx={{ fontSize: '16px', color: '#fff' }}>
              Copyright 2024 Lets Notify. All rights reserve. Various trademarks
              held by their respective owners. 
              <Link className="underline" target="#" to="/termsandcondition">
                Terms & Conditions
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
  );
});

export default Footer;
