import React, { useState } from 'react';
import { Box, Switch, TextField, Typography, Card, Tab } from '@mui/material';
import toast from 'react-hot-toast';
import { CreateTemplate } from 'Api/Api';
import { TemplateStyles } from './Style';
import { Loading, SkeletonLoading } from 'components/Loading';
import { SideDrawer } from 'components/SideDrawer';
import { NameValidation, UrlValidation } from 'utils/commonFunctions';
import { InnerTextField } from 'components/InputFields';
import Preview from './Enlarge/Preview';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import logoSrc from 'assets/Images/Common/letsnotify.png';

const CreateTemplateModal = ({ open, onClose, refresh, welcome,refreshTemplate }) => {
  const [buttonToggle, setButtonToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const [templateDetails, setTemplateDetails] = useState({
    templateName: '',
    title: '',
    message: '',
    pageURL: '',
    icon: '',
    banner: '',
    status: 'Active',
    welcomeTemplate: welcome,
  });
  const [errors, setErrors] = useState({
    templateName: false,
    title: false,
    message: false,
    pageURL: false,
    icon: false,
    banner: false,
  });

  const handleToggle = (e) => {
    const name = e.target.name;
    if (name === 'button_toggle') {
      setButtonToggle(!buttonToggle);
    }
    if (name === 'status') {
      const newStatus =
        templateDetails?.status === 'Active' ? 'Inactive' : 'Active';
      setTemplateDetails((prevState) => ({
        ...prevState,
        status: newStatus,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== 'banner' && name !== 'icon') {
      setTemplateDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === '' ? true : false,
      }));
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'banner' || name === 'icon') {
      const file = files[0];
      setTemplateDetails((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: !file,
      }));
    }
  };

  const handleSubmit = async () => {
    const validationRules = {
      templateName: {
        required: true,
        validator: NameValidation,
        errorMessage:
          'Invalid template name, the field supports only alphanumeric characters.',
      },
      title: {
        required: true,
        validator: NameValidation,
        errorMessage:
          'Invalid title, the field supports only alphanumeric characters.',
      },
      message: { required: true },
      pageURL: {
        required: true,
        validator: UrlValidation,
        errorMessage:
          'Invalid URL. Please ensure the URL is properly formatted.',
      },
      banner: { required: true },
      icon: { required: true },
    };

    const newErrors = {};
    let hasError = false;

    Object.entries(validationRules).forEach(([field, rule]) => {
      if (
        rule.required &&
        (!templateDetails[field] ||
          (typeof templateDetails[field] === 'string' &&
            !templateDetails[field].trim()))
      ) {
        newErrors[field] = true;
        hasError = true;
      } else if (rule.validator && !rule.validator(templateDetails[field])) {
        toast.error(rule.errorMessage);
        hasError = true;
      }
    });

    setErrors(newErrors);

    if (!hasError) {
      setLoading(true);
      try {
        let formData = new FormData();
        Object.entries(templateDetails).forEach(([key, value]) => {
          formData.append(key, value);
        });
        const response = await CreateTemplate(formData);
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          
          if (refreshTemplate !== undefined) {
            refreshTemplate()
          }
          refresh();
          onClose();
          setLoading(false);
          setTemplateDetails({ status: 'Active' });
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };


  return (
    <>
      <Loading state={loading} />
      <SideDrawer
        open={open}
        onClose={onClose}
        title={'New Template'}
        handleSubmit={handleSubmit}
        EyeIcon={true}
        isPreview={isPreview}
        setIsPreview={setIsPreview}
      >
        {!isPreview && (
          <Box>
            <Box>
              <InnerTextField
                required
                error={errors.templateName}
                name="templateName"
                label="Template Name"
                value={templateDetails?.templateName}
                placeholder="Enter template name"
                onChange={handleChange}
                helperText={errors.templateName ? 'Field required' : ''}
              />
            </Box>

            <Box>
              <InnerTextField
                required
                error={errors.title}
                name="title"
                label="Title"
                value={templateDetails?.title}
                onChange={handleChange}
                helperText={errors.title ? 'Field required' : ''}
              />
            </Box>
            <Box>
              <InnerTextField
                required
                error={errors.pageURL}
                name="pageURL"
                label="Redirecting URL"
                value={templateDetails?.pageURL}
                placeholder="http://example.com"
                onChange={handleChange}
                helperText={errors.pageURL ? 'Field required' : ''}
              />
            </Box>
            <Box mb={2}>
              <InnerTextField
                required
                multiline
                error={errors.message}
                name="message"
                label="Message"
                rows={2}
                value={templateDetails?.message}
                onChange={handleChange}
                helperText={errors.message ? 'Field required' : ''}
              />
            </Box>
            <Typography variant="subtitle3">
              Upload Notification Icon{' '}
            </Typography>
            <Box>
              <TextField
                type="file"
                name="icon"
                fullWidth
                margin="dense"
                error={errors.icon}
                onChange={handleFileChange}
                helperText={errors.icon ? 'Field required' : ''}
                sx={{
                  '& .MuiOutlinedInput-root': TemplateStyles.InputBorder,
                  '& input::file-selector-button':
                    TemplateStyles.fileUploadButton,
                  '& input::file-selector-button:hover': {
                    backgroundColor: '#045e50',
                  },
                }}
              />
            </Box>
            <br />
            <Typography variant="subtitle3">Upload Banner Image</Typography>
            <Box>
              <TextField
                type="file"
                name="banner"
                fullWidth
                margin="dense"
                error={errors.banner}
                onChange={handleFileChange}
                helperText={errors.banner ? 'Field required' : ''}
                sx={{
                  '& .MuiOutlinedInput-root': TemplateStyles.InputBorder,
                  '& input::file-selector-button':
                    TemplateStyles.fileUploadButton,
                  '& input::file-selector-button:hover': {
                    backgroundColor: '#045e50',
                  },
                }}
              />
            </Box>
            {/* <Box>
        <FormControlLabel
            control={<Switch checked={buttonToggle} onChange={handleToggle} />}
            label="Buttons"
            labelPlacement="start"
            name="button_toggle"
            sx={{ marginLeft: "0px" }}
          />

          {buttonToggle && (
            <>
              <br />
              <Typography variant="black_h4">Button 1</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "2rem",
                }}
                my={1}
              >
                <TextField
                  label="Title"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <TextField
                  label="Button Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
              </Box>
              <br />
              <Typography variant="black_h4">Button 2</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "2rem",
                }}
                my={1}
              >
                <TextField
                  label="Title"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
                <TextField
                  label="Button Link"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
              </Box>
            </>
          )}
        </Box> */}
            <br />
            <Box>
              <Typography variant="black_4">Status</Typography>
              <Switch
                name="status"
                size="large"
                checked={templateDetails?.status === 'Active'}
                onChange={handleToggle}
              />
            </Box>
          </Box>
        )}

        {isPreview && <Preview />}
      </SideDrawer>
    </>
  );
};

export default CreateTemplateModal;

const getPositionStyles = (position) => {
  switch (position) {
    case 'center':
      return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    case 'top':
      return { display: 'flex', justifyContent: 'center' };
    case 'top-left':
      return { display: 'flex', justifyContent: 'start', alignItems: 'start' };
    case 'top-right':
      return { display: 'flex', justifyContent: 'end', alignItems: 'start' };
    case 'bottom-left':
      return { display: 'flex', justifyContent: 'start', alignItems: 'end' };
    case 'bottom-right':
      return { display: 'flex', justifyContent: 'end', alignItems: 'end' };
    default:
      return {};
  }
};
