import React from 'react';
import { Outlet, Route, Router, Routes } from 'react-router-dom';
import Sequence from './Sequence';
import SequenceOption from './SequenceOption';
import SequenceSubscribers from './SequenceSubscriber';
import SequenceReview from './SequenceReview';
import CreateSequence from './CreateSequence';
import SequenceBoard from './SequenceBoard';

const SequenceLayout = () => {
  return (
    <Routes>
      <Route index element={<Sequence />} />
      <Route path="create" element={<CreateSequence />}>
        <Route path="options" element={<SequenceOption />} />
        <Route path="subscribers" element={<SequenceSubscribers />} />
        <Route path="sequence" element={<SequenceBoard />} />
        <Route path="review" element={<SequenceReview />} />
      </Route>

      <Route path=":id" element={<Outlet />}>
        <Route path="options" element={<SequenceOption />} />
        <Route path="subscriber" element={<SequenceSubscribers />} />
        <Route path="sequence" element={<SequenceBoard />} />
        <Route path="review" element={<SequenceReview />} />
      </Route>
    </Routes>
  );
};

export default SequenceLayout;
