import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetUserDetails, LoginUser } from 'Api/Api';
import { CustomFormInput } from 'components/Style';
import { RoundedButton } from 'components/Buttons';
import { setAuthDetails } from '../../../redux/reducers/authReducer';
import { isEmailValid } from 'utils/commonFunctions';
import { useForm } from 'react-hook-form';
import { protectedRoutes } from 'constants/appRoutes';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const LoginContainer = ({ showSignUp, openForgetPass }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  let flag = false;

  function onChangeReCaptcha(e) {
    setLoginDetails((x) => ({ ...x, ['reCaptcha']: e }));
  }

  const handleLogin = async (data) => {
    if (!loginDetails.reCaptcha) {
      toast.error("Please verify that you're not a robot");
      return;
    }
    try {
      const response = await toast.promise(LoginUser(data), {
        loading: 'Fetching data...',
        success: (response) => response?.data?.message,
        error: (error) => error?.response?.data?.message,
      });
      if (response?.data?.status === true) {
        var token = response?.data?.accessToken;
        var userDetails = response?.data?.user;
        localStorage.clear();
        localStorage.setItem('token', token);
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        const payload = {
          token: token,
          authUser: true,
        };
        dispatch(setAuthDetails(payload));
        flag = true;
      }
    } catch (error) {}
    try {
      if (flag) {
        const response = await GetUserDetails();
        if (
          response?.data?.status === true &&
          response?.data?.data?.customerData !== null
        ) {
          navigate(protectedRoutes.connections);
        } else {
          navigate(protectedRoutes.questions);
        }
      } else {
        return;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Box
      sx={{
        padding: 3,
        boxShadow: 3,
        borderRadius: 2,
        width: '100%',
        maxWidth: 400,
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="h5" textAlign="center">
        Welcome
      </Typography>
      <Typography variant="subtitle2" textAlign="center">
        Please enter your credentials to Sign In
      </Typography>
      <form>
        <Stack direction={'column'} spacing={2} mt={5} mb={1}>
          <Box>
            <Typography mb={1} variant="subtitle2">
              Email
            </Typography>
            <CustomFormInput
              placeholder="example@gmail.com"
              {...register('email', {
                required: 'Field required*',
                validate: {
                  validEmail: (value) =>
                    isEmailValid(value) || 'Email format is not valid',
                },
              })}
            />
            {errors.email && (
              <Typography sx={{ color: 'error.main' }}>
                {errors?.email?.message}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography mb={1} variant="subtitle2">
              Password
            </Typography>
            <Box sx={{ position: 'relative' }}>
              <CustomFormInput
                placeholder="Enter your password"
                type={showPassword ? 'text' : 'password'}
                {...register('password', {
                  required: {
                    value: true,
                    message: 'Field required*',
                  },
                })}
              />
              <IconButton
                disableRipple
                onClick={() => setShowPassword((prev) => !prev)}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'text.secondary',
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}{' '}
              </IconButton>
            </Box>
            {errors.password && (
              <Typography sx={{ color: 'error.main' }}>
                {errors?.password?.message}
              </Typography>
            )}
          </Box>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'start',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={openForgetPass}
          >
            Forgot Password?
          </Typography>
        </Box>
        <Box my={3} px={3}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={onChangeReCaptcha}
          />
        </Box>
        <Box textAlign={'center'} mt={3}>
          <RoundedButton title="Login" onClick={handleSubmit(handleLogin)} />
        </Box>
        <Typography variant="subtitle2" textAlign={'center'} my={3}>
          Don’t have an account yet?{' '}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={showSignUp}
          >
            Sign up
          </span>
        </Typography>
      </form>
    </Box>
  );
};
