import React, { useMemo, useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import StartNode from './components/Nodes/startNode';
import AddNode from './components/Nodes/addNode';
import CustomNode from './components/Nodes/customNode';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateSequence } from 'Api/Api';
import toast from 'react-hot-toast';
import { resetState } from '../../../redux/reducers/sequenceReducer';
import { protectedRoutes } from 'constants/appRoutes';
import { DateAndTime } from 'utils/commonFunctions';

const SequenceReview = ({ handleBack, handleNext }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { nodes: reduxNodes, edges: reduxEdges } = useSelector(
    (state) => state.sequence.sequence
  );
  const sequence = useSelector((state) => state.sequence.sequence);
  const options = useSelector((state) => state.sequence.options);
  const subscribers = useSelector((state) => state.sequence.subscribers);
  const [nodes, setNodes, onNodesChange] = useNodesState(reduxNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(reduxEdges);
  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const nodeTypes = useMemo(
    () => ({
      custom: (props) => <CustomNode {...props} edges={reduxEdges} />,
      startNode: (props) => <StartNode {...props} edges={reduxEdges} />,
      addNode: (props) => <AddNode {...props} edges={reduxEdges} />,
    }),
    []
  );
  const time = DateAndTime(options?.frequencyDateTime);

  const dataToSave = {
    options: {
      schedule: options.schedule,
      triggerCampaign: options.triggerCampaign,
      subscribersInteractionNeeded: options.subscribersInteractionNeeded,
      frequencyDateTime: options.frequencyDateTime,
      frequency: options.frequency,
    },
    sequence: {
      nodes: sequence.nodes,
      edges: sequence.edges,
    },
    subscribers: {
      sequenceName: subscribers.sequenceName,
      selectedSegment: subscribers.selectedSegment,
      subscriberCount: subscribers.subscriberCount,
    },
  };

  const PreviousPage = () => {
    if (id) {
      navigate('/sequence/' + id + '/options');
    } else {
      handleBack();
    }
  };

  const SaveData = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let formData = new FormData();
      Object.entries(dataToSave).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const response = await UpdateSequence(id, formData);
      if (response?.data?.status === true) {
        toast.success(response.data.message);
        setLoading(false);
        dispatch(resetState());
        navigate(protectedRoutes.sequence);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data.message);
    }
  };

  return (
    <Box
      mt={2}
      sx={{
        position: 'relative',
        height: '75vh',
        display: 'flex',
        width: '100%',
        paddingY: 5,
        paddingRight: 10,
      }}
    >
      <Box
        sx={{
          flex: 2,
          borderRadius: '16.772px',
          border: '1px solid #000',
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          fitView
        >
          <Background />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          paddingX: 2,
        }}
      >
        <Box
          sx={{
            flex: 0.5,
            borderRadius: '16.772px',
            border: '1px solid #000',
            marginBottom: 2,
          }}
        >
          <Typography fontWeight={400} fontSize={20} p={2} color={'black'}>
            Subscriber
          </Typography>
          <Box>
            <Typography
              pl={2}
              fontWeight={'semiBold'}
              variant="black_h4"
              sx={{ fontSize: 25 }}
            >
              Subscriber Count = {subscribers?.subscriberCount}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            marginBottom: 2,
            borderRadius: '16.772px',
            border: '1px solid #000',
          }}
        >
          <Typography fontWeight={500} fontSize={20} p={2} color={'black'}>
            Options
          </Typography>

          <Typography
            pl={2}
            fontWeight={'semiBold'}
            color={'#616161'}
            sx={{ fontSize: 20 }}
          >
            {' '}
            {`Schedule ${options?.schedule} on ${time}`}
          </Typography>

          <Typography
            pl={2}
            mb={2}
            fontWeight={'semiBold'}
            color={'#616161'}
            sx={{ fontSize: 20 }}
          >
            {options?.triggerCampaign &&
              'Trigger Campaign based on the Subscriber Timezone'}
          </Typography>
          <Typography
            pl={2}
            mb={2}
            fontWeight={'semiBold'}
            color={'#616161'}
            sx={{ fontSize: 20 }}
          >
            {options?.subscribersInteractionNeeded &&
              'Subscribers Interaction needed'}
          </Typography>
          <Typography
            pl={2}
            mb={2}
            fontWeight={'semiBold'}
            color={'#616161'}
            sx={{ fontSize: 20 }}
          >
            {options?.convertIntoAutomation && 'Converted into Automation'}
          </Typography>
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: -40,
          height: '60px',
          display: 'flex',
          gap: 4,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          backgroundColor={'#f0f2f5'}
          variant="outlined"
          size="large"
          onClick={PreviousPage}
        >
          Draft
        </Button>
        <Button
          backgroundColor={'#f0f2f5'}
          variant="contained"
          size="large"
          onClick={SaveData}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default SequenceReview;
