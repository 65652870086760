import { DeleteSequence, FetchAllSequence, UpdateSequence } from 'Api/Api';
import { DeleteModal } from 'components/Modals';
import { ContainerStyle, MainDashboard } from 'components/Style';
import { SequenceTableColumns } from 'constants/appConstant';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  resetState,
  updateToEdit,
} from '../../../redux/reducers/sequenceReducer';
import NoRecord from 'components/NoRecord';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Box, Button, Slide, Stack, Typography } from '@mui/material';
import ListView from 'components/ListView';
import { protectedRoutes } from 'constants/appRoutes';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Sequence = () => {
  const [sequenceData, setSequenceData] = useState([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState();
  const [loading, setLoading] = useState({});
  const [searchTerms, setSearchTerms] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const NavigateToCreate = () => {
    navigate(protectedRoutes.createSequence);
  };

  const EditSequence = (record) => {
    dispatch(updateToEdit(record));
    navigate(`/sequence/${record.id}/subscriber`);
  };

  const handleDeleteModel = (recordId) => {
    setDeleteModalOpen(true);
    setDeleteId(recordId);
  };

  const handleEditModel = (recordId, isDetail) => {
    setEditModalOpen(true);
    setEditId(recordId);
  };

  const onClose = () => {
    setCreateModalOpen(false);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  async function fetch() {
    try {
      setInitialLoading(true);
      let response = await FetchAllSequence();
      if (response?.data?.status === true) {
        setSequenceData(response?.data?.data);
        setInitialLoading(false);
      }
    } catch (error) {
      // toast.error('Failed to fetch sequence');
    } finally {
      setInitialLoading(false);
    }
  }

  async function updateStatus(item) {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [item.id]: true,
    }));
    try {
      const response = await UpdateSequence(item?.id, {
        id: item.id,
        segment_name: item.segment_name,
        status: item.status === 'Active' ? 'Inactive' : 'Active',
      });
      if (response?.data?.status === true) {
        fetch();
      }
    } catch (error) {
      toast.error('Failed to update status.');
    } finally {
      setLoading((prevLoading) => ({
        ...prevLoading,
        [item.id]: false,
      }));
    }
  }

  async function deleteSequenceFunc(recordId) {
    try {
      const response = await DeleteSequence(recordId);
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    fetch();
    onClose();
  }, []);

  useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <MainDashboard>
      <Box sx={ContainerStyle.container}>
        <Stack direction="row" sx={ContainerStyle.topBar}>
          <Typography variant="h5" sx={ContainerStyle.heading}>
            Sequence
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddToPhotosIcon />}
            onClick={NavigateToCreate}
            size="large"
            sx={{ width: '150px', marginLeft: '16px', marginBottom: '6px' }}
          >
            Create
          </Button>
        </Stack>
        <Box sx={ContainerStyle.listView}>
          <ListView
            tableData={sequenceData}
            rowData={SequenceTableColumns}
            handleEditModel={EditSequence}
            handleDeleteModel={handleDeleteModel}
            updateStatus={updateStatus}
            initialLoading={initialLoading}
            loading={loading}
            sortingIndex={[
              'status',
              'sequenceName',
              'createdTime',
              'modifiedTime',
            ]}
            searchTerms={searchTerms}
            setSearchTerms={setSearchTerms}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            noRecordComponent={
              <NoRecord
                type="callback"
                moduleName="Sequence"
                onAction={NavigateToCreate}
              />
            }
          />
        </Box>
        <DeleteModal
          open={deleteModalOpen}
          close={onClose}
          placeholder="campaign"
          deleteFunction={() => deleteSequenceFunc(deleteId)}
        />
      </Box>
    </MainDashboard>
  );
};

export default Sequence;
