import React, { useEffect, useRef } from 'react';
import grapesjs from 'grapesjs';
import customCodePlugin from 'grapesjs-custom-code';
import grapesjsNavbar from 'grapesjs-navbar';
import CustomBlocks from '../builder/CustomBuilder';
import blockBasic from "grapesjs-blocks-basic";
import FormPlugin from "grapesjs-plugin-forms";
import 'grapesjs/dist/css/grapes.min.css';
import './editor.css';

const Editor = () => {
    const editorRef = useRef(null);

    useEffect(() => {
        if (!editorRef.current) {
            editorRef.current = grapesjs.init({
                container: '#gjs',
                height: '100vh',
                fromElement: true,
                plugins: [customCodePlugin, grapesjsNavbar, blockBasic, FormPlugin],
                pluginsOpts: {
                    'grapesjs-custom-code': {
                        blockCustomCode: {
                            label: 'Custom Code',
                            category: 'Extra',
                        },
                    },
                },
            });
            CustomBlocks(editorRef.current);
        }
    }, []);

    return <div id="gjs" className='px-2' />;
};

export default Editor;