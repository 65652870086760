import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';

export default function DateTimepicker({ label, onChangeval }) {
  const currentDate = dayjs();

  const customTheme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#000000', 
          },
        },
      }, 
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000000',
              borderWidth: '1px', 
            },
          },
          notchedOutline: {
            borderWidth: '1px', 
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#000000',
            '&.Mui-focused': {
              color: '#000000', 
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#058270', 
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#04695b', 
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: '#000000', 
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#058270',
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker']}>
          <DateTimePicker
            label={label}
            defaultValue={currentDate}
            minDate={currentDate}
            onChange={(newDate) => {
              if (newDate.isAfter(currentDate)) {
                const dateString = newDate.format('YYYY-MM-DD HH:mm:ss');
                onChangeval(dateString);
              } else {
                console.log(
                  'Selected date must be after the current date and time.'
                );
              }
            }}
            sx={{
              '& .MuiInputBase-input': {
                color: '#000000', 
              },
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
