import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  Zoom,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonIcon from '@mui/icons-material/Person';
import {
  DeleteProfileImage,
  GenerateNewAccessKey,
  GetAccessKey,
  GetUserDetails,
  UpdateProfile,
} from 'Api/Api';
import toast from 'react-hot-toast';
import {
  avatar,
  deleteGrid,
  hoveredAvatar,
  mainContainer,
  profileContainer,
  profileGrid,
  securityGrid,
} from './style';
import { CustomFormInput } from 'components/Style';
import { ContainedButton, OutlinedButton } from 'components/Buttons';
import EmailLink from 'components/Email';
import { MobileNumberValidation, NameValidation } from 'utils/commonFunctions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { InnerTextField } from 'components/InputFields';
import CustomTooltip from 'components/Tooltip';

const ProfileScreen = () => {
  const [userDetails, setUserDetails] = useState({
    userName: '',
    email: '',
    mobileNumber: '',
    companyName: '',
    role: '',
    profileImage: '',
    profileImageURL: '',
  });
  const [errors, setErrors] = useState({
    userName: false,
    email: false,
    mobileNumber: false,
    companyName: false,
    role: false,
    profileImage: false,
  });
  const [updateProfile, setUpdateProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [accessKey, setAccessKey] = useState('');
  const [image, setImage] = useState(userDetails?.profileImageURL || '');
  const [hovered, setHovered] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const navigateBack = () => {
    window.history.back();
  };

  const handleCopy = () => {
    if (accessKey) {
      navigator.clipboard.writeText(accessKey).then(() => {
        toast.success('Access key copied to clipboard');
      });
    }
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (confirmed) => {
    if (confirmed) {
      generateAccessKey();
    }
    setOpenDialog(false);
  };

  const fetch = async () => {
    try {
      setIsLoading(true);
      const response = await GetUserDetails();
      if (response?.data?.status === true) {
        const { profileImageURL, ...restItem } = response?.data?.data;
        setUserDetails(restItem);
        setImage(profileImageURL);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchAccessKey = async () => {
    try {
      const response = await GetAccessKey();
      if (response?.data?.status === true) {
        setAccessKey(response?.data?.accessKey);
      }
    } catch (error) {
      toast.success(error?.response?.data?.message);
    }
  };

  const generateAccessKey = async () => {
    try {
      const response = await GenerateNewAccessKey();
      if (response?.data?.status === true) {
        setAccessKey(response?.data?.accessKey);
        toast.success(response?.data?.message);
        fetchAccessKey();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== 'profileImage') {
      let isValid = true;
      if (name === 'userName') {
        isValid = NameValidation(value);
      } else if (name === 'mobileNumber') {
        isValid = MobileNumberValidation(value);
      }
      setUserDetails((prev) => ({ ...prev, [name]: value }));
      setUpdateProfile((prev) => ({ ...prev, [name]: value }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: !isValid || value.trim() === '',
      }));
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      setUserDetails((prev) => ({ ...prev, [name]: file }));
      setUpdateProfile((prev) => ({ ...prev, [name]: file }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let formData = new FormData();
      Object.entries(updateProfile).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const response = await UpdateProfile(formData);
      if (response?.data?.status === true) {
        toast.success(response?.data?.message);
        fetch();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data?.message);
    }
  };

  const deleteProfileImage = async (e) => {
    e.preventDefault();
    if (userDetails.profileImage !== '') {
      try {
        setIsLoading(true);
        const response = await DeleteProfileImage();
        if (response?.data?.status === true) {
          toast.success(response?.data?.message);
          setImage('');
          fetch();
        }
      } catch (error) {
        setIsLoading(false);
        toast.error(error.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    fetch();
    fetchAccessKey();
  }, []);

  return (
    <Box sx={mainContainer}>
      {/* <Loading state={isLoading} /> */}
      <Typography variant="dashboardTitle">My Accounts</Typography>
      <Box sx={profileContainer}>
        <Grid container sx={profileGrid}>
          <Typography variant="black_h3">Profile</Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  className="avatar-wrapper"
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  <Avatar
                    alt="Profile Picture"
                    src={image}
                    className="profile-pic"
                    sx={avatar}
                  >
                    {(!image || hovered) && <PersonIcon fontSize="large" />}
                  </Avatar>
                  <input
                    type="file"
                    id="profileImage"
                    name="profileImage"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  {hovered && (
                    <label htmlFor="profileImage">
                      <IconButton
                        className="upload-button"
                        component="span"
                        sx={hoveredAvatar}
                      >
                        <CloudUploadIcon fontSize="large" />
                      </IconButton>
                    </label>
                  )}
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton
                    onClick={() => {}}
                    sx={{
                      border: '2px solid #BEBFC5',
                      borderRadius: 2,
                      p: 0.5,
                    }}
                  >
                    <DeleteIcon
                      sx={{ color: '#FF3800' }}
                      onClick={deleteProfileImage}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={8.7} mt={-1} mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Full Name
                    </Typography>
                    <InnerTextField
                      required
                      error={errors.userName}
                      name="userName"
                      margin="none"
                      value={userDetails?.userName || ''}
                      placeholder="Enter template name"
                      onChange={handleChange}
                      helperText={errors.userName ? 'Field required' : ''}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Email
                    </Typography>
                    <InnerTextField
                      required
                      name="email"
                      margin="none"
                      value={userDetails?.email || ''}
                      placeholder="Enter your email"
                      onChange={handleChange}
                      helperText={errors.email ? errors.email.message : ''}
                      tooltip={true}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Mobile Number
                    </Typography>
                    <InnerTextField
                      required
                      error={errors.mobileNumber}
                      name="mobileNumber"
                      margin="none"
                      value={userDetails?.mobileNumber || ''}
                      placeholder="Enter template name"
                      onChange={handleChange}
                      helperText={errors.mobileNumber ? 'Field required' : ''}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Organisation Name
                    </Typography>
                    <InnerTextField
                      required
                      error={errors.companyName}
                      name="companyName"
                      margin="none"
                      value={userDetails?.companyName || ''}
                      placeholder="Enter template name"
                      onChange={handleChange}
                      helperText={errors.companyName ? 'Field required' : ''}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Stack direction="column" spacing={0.5}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      Role
                    </Typography>
                    <InnerTextField
                      required
                      error={errors.role}
                      name="role"
                      margin="none"
                      value={userDetails?.role || ''}
                      placeholder="Enter template name"
                      onChange={handleChange}
                      helperText={errors.role ? 'Field required' : ''}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={securityGrid}>
          <Typography variant="black_h3">Security</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Stack direction="column" spacing={0.5} flexGrow={1}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Access Key
              </Typography>

              <CustomFormInput
                name="role"
                type="text"
                fullWidth
                margin="normal"
                placeholder="Enter your access key"
                value={accessKey || ''}
                disabled
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopy}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Button
              sx={{
                padding: '0px 13px',
                backgroundColor: '#15B097',
                mt: 4,
                color: 'white',
                width: 'auto',
                minWidth: '218.5px',
                height: '52px',
                '&:hover': { backgroundColor: '#07826F' },
              }}
              size="large"
              onClick={handleClickOpen}
            >
              Generate new key
            </Button>

            <Dialog
              open={openDialog}
              onClose={() => handleClose(false)}
              TransitionComponent={Zoom}
              PaperProps={{
                sx: {
                  borderRadius: '12px',
                  paddingY: '24px',
                  px: 3,
                  width: '500px',
                  overflowY: 'hidden',
                  position: 'absolute',
                  top: '10%',
                  left: '50%',
                  transform: 'translate(-50%, 0)',
                  boxShadow: `0px 5px 10px rgba(117, 117, 117, 0.1),
                  0px 19px 19px rgba(117, 117, 117, 0.09),
                  0px 42px 25px rgba(117, 117, 117, 0.05),
                  0px 75px 30px rgba(117, 117, 117, 0.01),
                  0px 117px 33px rgba(117, 117, 117, 0)`,
                },
              }}
            >
              <Box>
                <Typography
                  textAlign="center"
                  variant="h5"
                  sx={{ mb: 1, fontWeight: 600 }}
                >
                  Confirm Access Key Change
                </Typography>

                <Typography
                  color="#0D0D0D"
                  fontWeight={400}
                  textAlign="center"
                  sx={{ mb: 1, lineHeight: 1.5 }}
                >
                  If you create a new access key, the old one will become
                  invalid and will need to be updated.
                </Typography>

                <DialogActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleClose(false)}
                    sx={{
                      backgroundColor: '#fff',
                      color: 'black',
                      border: '1px solid grey',
                      px: 4,
                      py: 1,
                      borderRadius: '8px',
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleClose(true)}
                    sx={{
                      color: '#fff',
                      px: 4,
                      py: 1,
                      borderRadius: '8px',
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
                    }}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Box>
            </Dialog>
          </Box>
        </Grid>

        <Box sx={deleteGrid}>
          <Typography variant="black_h3">Delete account</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="black_h5">
              Keep in mind that upon deleting your account all of your account
              information will be deleted without the possibility of
              restoration.
            </Typography>
            <CustomTooltip
              title={
                <>
                  We currently do not support account deletion. For assistance,
                  please contact us via email - <EmailLink />
                </>
              }
              placement="top-start"
              arrow
            >
              <Button
                variant="outlined"
                sx={{
                  border: '1px solid #FF3800 !important',
                  cursor: 'not-allowed',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: '150px',
                }}
              >
                <IconButton sx={{ cursor: 'not-allowed' }}>
                  <DeleteIcon fontSize="30" sx={{ color: '#FF3800' }} />
                </IconButton>
                <Typography sx={{ fontWeight: '500', color: '#FF3800' }}>
                  Delete Account
                </Typography>
              </Button>
            </CustomTooltip>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={4}
          mt={1}
        >
          <OutlinedButton
            title="Cancel"
            sx={{ width: '100px' }}
            onClick={navigateBack}
          />
          <ContainedButton
            title="Save"
            onClick={handleSubmit}
            sx={{ width: '100px' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileScreen;
