
export const unprotectedRoutes = {
  login: '/login',
  termsAndCondition: '/termsandcondition'
};

export const AppScreenRoute = '';

export const protectedRoutes = {
  app: '/*',
  home: AppScreenRoute + '/home',
  questions: AppScreenRoute + '/questions',
  createConnect: '/create-connection',
  dashboard: AppScreenRoute+'/dashboard',
  codeInjection: '/code-injection',
  connections: AppScreenRoute + '/connections',
  customPrompt: AppScreenRoute + '/connections/custom-prompt/*',
  templates: AppScreenRoute + "/templates",
  sequence: AppScreenRoute + "/sequence",
  createSequence: AppScreenRoute + "/sequence/create",
  webCampaigns: AppScreenRoute + "/web-campaigns",
  realtimeCampaigns: AppScreenRoute + "/realtime-campaigns",
  analytics: AppScreenRoute + "/analytics",
  feeds: AppScreenRoute + "/feeds",
  subscribers: AppScreenRoute + "/subscribers",
  segments: AppScreenRoute + "/segments",
  profile: AppScreenRoute + "/profile",
  webBuilder: AppScreenRoute + "/web-builder",
};