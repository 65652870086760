import { Box, Icon, Tabs } from '@mui/material';
import EnlargeModal, {
  a11yProps,
  CustomTab,
  CustomTabPanel,
  SvgSeparator,
} from 'components/EnlargeModal';
import { useState } from 'react';
import { Welcome } from './Enlarge/Welcome';
import { CustomPrompt } from './Enlarge/CustomPrompt';
import { Detail } from './Enlarge/Detail';
import Activity from './Enlarge/Activity';
import { svgBorder } from 'constants/appConstant';
import DetailIconInActive from '../../../assets/Icons/SideBar/details.png';
import DetailIconActive from '../../../assets/Icons/SideBar/details 1.png';
import ActivityIconInActive from '../../../assets/Icons/SideBar/activity-1.png';
import ActivityIconActive from '../../../assets/Icons/SideBar/activity.png';
import CustomPromptIconInActive from '../../../assets/Icons/SideBar/custom prompt black.png';
import CustomPromptIconActive from '../../../assets/Icons/SideBar/custom prompt.png';
import TemplateIconInActive from '../../../assets/Icons/SideBar/template-1.png';
import TemplateIconActive from '../../../assets/Icons/SideBar/template.png';

const EnlargeCustomModal = (
  { 
    open, 
    onCloseModal, 
    item, 
    refresh,
    initialLoading,
    welcomeTemplate,
    refreshTemplate,
    customPrompt,
    refreshCustom,
    setCustomPrompts,
    setWelcomeTemplates
  }) => {
  const [value, setValue] = useState(0);
  const [shouldFetchWelcome, setShouldFetchWelcome] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 2) {
      setShouldFetchWelcome(true);
    }
  };

  return (
    <EnlargeModal open={open} onCloseModal={onCloseModal} title={'Connections'}>
      <Box width={'100%'}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ minHeight: '40px', }}
          >
            <CustomTab
              label="Details"
              {...a11yProps(0)}

              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={value === 0 ? DetailIconActive : DetailIconInActive}
                    alt="Subscribers Icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
              segtext='true'
            />

            <SvgSeparator />

            <CustomTab
              label="Custom Prompt"
              {...a11yProps(2)}

              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={value === 2 ? CustomPromptIconActive : CustomPromptIconInActive}
                    alt="Subscribers Icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
              segtext='true'

            />

            <SvgSeparator />

            <CustomTab
              label="Welcome Template"
              {...a11yProps(4)}

              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={value === 4 ? TemplateIconActive : TemplateIconInActive}
                    alt="Subscribers Icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
              segtext='true'

            />

            <SvgSeparator />

            <CustomTab
              label="Activity"
              {...a11yProps(6)}

              icon={
                <Icon sx={{ marginTop: '5px', marginRight: '0px !important' }}>
                  <img
                    src={value === 6 ? ActivityIconActive : ActivityIconInActive}
                    alt="Subscribers Icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </Icon>
              }
              iconPosition="start"
              segtext='true'

            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Detail item={item} refresh={refresh} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CustomPrompt item={item} 
            initialLoading ={initialLoading}
            customPrompt={customPrompt}
            refresh={refresh}
            refreshCustom={refreshCustom}
            setCustomPrompts={setCustomPrompts}

            />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Welcome
            shouldFetchWelcome={shouldFetchWelcome}
            item={item}
            refresh={refresh}
            welcomeTemplate={welcomeTemplate}
            refreshTemplate={refreshTemplate}
            setWelcomeTemplates={setWelcomeTemplates}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <Activity item={item} />
        </CustomTabPanel>
      </Box>
    </EnlargeModal>
  );
};

export default EnlargeCustomModal;