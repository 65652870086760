const CustomBlocks = (editor) => {
    const Blocks = editor.BlockManager;

    //? Text Block
    Blocks.add('text', {
        label: 'Text',
        media: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z"/></svg>',
        category: 'Basic',
        content: '<div data-gjs-type="text">Insert your text here</div>',
    });

    //? Image Block
    Blocks.add('image', {
        label: 'Image',
        media: '<svg viewBox="0 0 24 24"><path d="M21 19V5H3v14H21zm-2-2H5V7h14v10zm-6-3.5l2.25 3 3-4L19 17H5l4-5.25L12.75 13.5z"></path></svg>',
        category: 'Basic',
        content: { type: 'image' },
        activate: true,
    });

    //? Section Block
    Blocks.add('section', {
        label: '<b>Section</b>',
        media: '<svg viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg>',
        category: 'Basic',
        content: `
        <section>
        <h1>Section Title</h1>
        <p>This is a section with some text.</p>
        </section>
        `,
    });

    //? Link Block
    Blocks.add('link', {
        label: 'Link',
        media: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"/></svg>',
        category: 'Basic',
        content: {
            type: 'link',
            content: 'Insert link text here',
            attributes: { href: '#' }
        },
    });

    //? Quote Block
    Blocks.add('quote', {
        label: 'Quote',
        media: `
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z"></path>
            </svg>
        `,
        category: 'Basic',
        content: {
            type: 'text',
            content: '<blockquote>Insert your quote here</blockquote>',
            attributes: { class: 'quote-block' }
        }
    });

    //? Text Section Block
    Blocks.add('text-section', {
        label: 'Text Section',
        media: `
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M21,6V8H3V6H21M3,18H12V16H3V18M3,13H21V11H3V13Z"></path>
            </svg>
        `,
        category: 'Basic',
        content: {
            type: 'text',
            content: '<section><p>Insert your text here</p></section>',
            attributes: { class: 'text-section' }
        }
    });

    //? Link Video Block
    Blocks.add('link-video', {
        label: 'Video',
        media: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z"></path></svg>',
        category: 'Basic',
        content: {
            type: 'video',
            src: 'https://www.youtube.com/embed/your-video-id',
        },
    });

    //? Map Block
    Blocks.add('map', {
        label: 'Map',
        media: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M20.5,3L20.34,3.03L15,5.1L9,3L3.36,4.9C3.15,4.97 3,5.15 3,5.38V20.5A0.5,0.5 0 0,0 3.5,21L3.66,20.97L9,18.9L15,21L20.64,19.1C20.85,19.03 21,18.85 21,18.62V3.5A0.5,0.5 0 0,0 20.5,3M10,5.47L14,6.87V18.53L10,17.13V5.47M5,6.46L8,5.45V17.15L5,18.31V6.46M19,17.54L16,18.55V6.86L19,5.7V17.54Z"></path></svg>',
        category: 'Basic',
        content: {
            type: 'map',
            src: 'https://maps.google.com/?q=your-address',
        },
    });

    //? iFrame Block
    Blocks.add('editable-iframe', {
        label: 'iFrame',
        category: 'Basic',
        media: `
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M21,6V8H3V6H21M3,18H12V16H3V18M3,13H21V11H3V13Z"></path>
            </svg>`,
        content: `
            <div class="iframe-container" style="border: 1px solid #ddd; padding: 10px; width: 100%; height: 350px;">
                <div data-gjs-type="iframe-content" style="width: 100%; height: 100%; overflow: auto;">
                    <h3>Editable iFrame Content</h3>
                    <p>Click here to edit the content inside the iFrame.</p>
                </div>
            </div>
        `,
    });

    //? Column Blocks
    Blocks.add('column', {
        label: 'Column',
        media: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M2 20h20V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Z"></path></svg>',
        category: 'Layout',
        content: `<div class="col"><p>Single column</p></div>`,
    });

    Blocks.add('two-columns', {
        label: '2 Columns',
        media: '<svg viewBox="0 0 23 24"><path fill="currentColor" d="M2 20h8V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM13 20h8V4h-8v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1Z"></path></svg>',
        category: 'Layout',
        content: `<div class="row">
        <div class="col"><p>Column 1</p></div>
        <div class="col"><p>Column 2</p></div>
        </div>`,
    });

    Blocks.add('three-columns', {
        label: '3 Columns',
        media: `<svg viewBox="0 0 23 24">
        <path fill="currentColor" d="M2 20h5V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM9 20h5V4H9v16Zm-1 0V4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1ZM16 20h5V4h-5v16Zm-1 0V4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1Z"></path>
        </svg>`,
        category: 'Layout',
        content: `<div class="row">
        <div class="col"><p>Column 1</p></div>
        <div class="col"><p>Column 2</p></div>
        <div class="col"><p>Column 3</p></div>
        </div>`,
    });

    Blocks.add('two-column-3-7', {
        label: '2 Column 3/7',
        media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M2 20h5V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM10 20h12V4H10v16Zm-1 0V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1Z"></path>
        </svg>`,
        category: 'Layout',
        content: `<div class="row">
        <div class="col-3"><p>Column 1 (3/10)</p></div>
        <div class="col-7"><p>Column 2 (7/10)</p></div>
        </div>`,
    });

    //? Form Block
    Blocks.add('form', {
        label: 'Form',
        media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 5.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 8H3V6h18v2zM22 10.5c0-.3-.5-.5-1.3-.5H3.4c-.8 0-1.3.2-1.3.5v3c0 .3.5.5 1.3.5h17.4c.8 0 1.3-.2 1.3-.5v-3zM21 13H3v-2h18v2z"></path><rect width="10" height="3" x="2" y="15" rx=".5"></rect></svg>',
        category: 'Forms',
        content: `<form><input type="text" placeholder="Your Name"/><button type="submit">Submit</button></form>`,
    });

    //? Input Field Block
    Blocks.add('input', {
        label: 'Input',
        media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"></path><path d="M4 10h1v4H4z"></path></svg>',
        category: 'Forms',
        content: '<input type="text" placeholder="Type here"/>',
    });

    //? Text Area Block
    Blocks.add('textarea', {
        label: 'Text Area',
        media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 7.5c0-.9-.5-1.5-1.3-1.5H3.4C2.5 6 2 6.6 2 7.5v9c0 .9.5 1.5 1.3 1.5h17.4c.8 0 1.3-.6 1.3-1.5v-9zM21 17H3V7h18v10z"></path><path d="M4 8h1v4H4zM19 7h1v10h-1zM20 8h1v1h-1zM20 15h1v1h-1z"></path></svg>',
        category: 'Forms',
        content: '<textarea placeholder="Type here"></textarea>',
    });

    //? Select Field Block
    Blocks.add('select', {
        label: 'Select',
        media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"></path><path d="M18.5 13l1.5-2h-3zM4 11.5h11v1H4z"></path></svg>',
        category: 'Forms',
        content: `<select><option value="Option 1">Option 1</option><option value="Option 2">Option 2</option></select>`,
    });

    //? Button Block
    Blocks.add('button', {
        label: 'Button',
        media: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fill="currentColor" d="M22 9c0-.6-.5-1-1.3-1H3.4C2.5 8 2 8.4 2 9v6c0 .6.5 1 1.3 1h17.4c.8 0 1.3-.4 1.3-1V9zm-1 6H3V9h18v6z"></path>
            <path fill="currentColor" d="M4 11.5h16v1H4z"></path>
        </svg>
    `,
        category: 'Forms',
        content: '<button>Click Me</button>',
    });

    //? Label Block
    Blocks.add('label', {
        label: 'Label',
        media: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fill="currentColor" d="M22 11.9c0-.6-.5-.9-1.3-.9H3.4c-.8 0-1.3.3-1.3.9V17c0 .5.5.9 1.3.9h17.4c.8 0 1.3-.4 1.3-.9V12zM21 17H3v-5h18v5z"></path>
            <rect fill="currentColor" width="14" height="5" x="2" y="5" rx=".5"></rect>
            <path fill="currentColor" d="M4 13h1v3H4z"></path>
        </svg>
    `,
        category: 'Forms',
        content: '<label>Label Text</label>',
    });

    //? Checkbox Block
    Blocks.add('checkbox', {
        label: 'Checkbox',
        media: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="currentColor" d="M10 17l-5-5 1.41-1.42L10 14.17l7.59-7.59L19 8m0-5H5c-1.11 0-2 .89-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2z"></path>
            </svg>
        `,
        category: 'Forms',
        content: {
            type: 'checkbox',
            content: `<div class="custom-checkbox">
                          <input type="checkbox" id="checkbox1">
                          <label for="checkbox1">Check me</label>
                      </div>`,
            attributes: { class: 'checkbox-block' }
        }
    });

    //? Radio Button Block
    Blocks.add('radio', {
        label: 'Radio Button',
        media: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8m0-18C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 5c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z"></path>
            </svg>
        `,
        category: 'Forms',
        content: {
            type: 'radio',
            content: `<div class="custom-radio">
                          <input type="radio" id="radio1" name="radio-group">
                          <label for="radio1">Option 1</label>
                          <input type="radio" id="radio2" name="radio-group">
                          <label for="radio2">Option 2</label>
                      </div>`,
            attributes: { class: 'radio-block' }
        }
    });

    //? Countdown Block
    Blocks.add('countdown', {
        label: 'Countdown',
        media: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M17 11.5V13H11V7H12.5V11.5H17Z"></path></svg>',
        category: 'Extra',
        content: `<div class="countdown" data-time="2024-12-31T23:59:59">
        <p>Countdown Timer</p>
        <p>00:00:00</p>
        </div>`,
    });

    //? Typed Text Block
    Blocks.add('typed-text', {
        label: 'Typed',
        media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><path d="M212.3 44l2.3 49.6h-6A60 60 0 00204 75c-3.2-6-7.5-10.5-12.9-13.3a44.9 44.9 0 00-21.1-4.3h-29.8V219c0 13 1.4 21 4.2 24.3 4 4.4 10 6.6 18.2 6.6h7.4v5.7H80.2V250h7.5c9 0 15.3-2.7 19-8.2 2.4-3.3 3.5-10.9 3.5-22.7V57.3H84.8a71 71 0 00-21.1 2.2 29 29 0 00-13.9 11.3 46.1 46.1 0 00-6.9 22.8H37L39.5 44h172.8zM245 22h18v256h-18z"></path></svg>',
        category: 'Extra',
        content: `<div class="typed-text" data-text="Hello, World!">Hello, World!</div>`,
    });

    //? Custom Code
    Blocks.add('custom-code', {
        label: 'Custom Code',
        media: `
          <svg viewBox="0 0 24 24">
            <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
          </svg>
        `,
        category: 'Extra',
        activate: true,
        select: true,
        content: { type: 'custom-code' },
    });

    //? Tooltip Block
    Blocks.add('tooltip', {
        label: 'Tooltip',
        media: `
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M4 2h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-4l-4 4-4-4H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2m0 2v12h4.83L12 19.17 15.17 16H20V4H4z"></path>
            </svg>
        `,
        category: 'Extra',
        content: `
            <div class="tooltip-container" style="position: relative; display: inline-block;">
                <span class="tooltip-text" style="visibility: hidden; background-color: black; color: #fff; text-align: center; border-radius: 5px; padding: 5px; position: absolute; z-index: 1; bottom: 125%; left: 50%; transform: translateX(-50%); opacity: 0; transition: opacity 0.3s;">
                    Tooltip Text
                </span>
                <span style="border-bottom: 1px dotted black; cursor: pointer;">Hover over me</span>
            </div>
        `,
        script: `
            const tooltipText = this.querySelector('.tooltip-text');
            this.onmouseenter = () => tooltipText.style.visibility = tooltipText.style.opacity = '1';
            this.onmouseleave = () => tooltipText.style.visibility = tooltipText.style.opacity = '0';
        `
    });
};

export default CustomBlocks;
