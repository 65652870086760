import { Box, Button, Typography } from '@mui/material';
import { searchContainerSX } from './Style';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import TerminalIcon from '@mui/icons-material/Terminal';
import { Link } from 'react-router-dom';

const SearchBar = ({
  placeHolder,
  handleOpen,
  helperDocs,
  customPrompt,
  secondButton,
  createButton = true,
  button = true,
}) => {
  return (
    <Box sx={searchContainerSX}>
      <Typography variant="dashboardTitle">{placeHolder}</Typography>
      <Box display="flex" gap={3} flexWrap="wrap">
        {!createButton ? (
          <Button
            size="large"
            variant="contained"
            onClick={helperDocs}
            sx={{
              height: 'auto',
              width: { xs: '100%', md: 'auto' },
              borderRadius: 1,
              display: secondButton ? { xs: 'none', md: 'block' } : 'none',
              flexGrow: 1,
            }}
          >
            Help&nbsp;Docs
          </Button>
        ) : (
          button && (
            <Button
              size="large"
              variant="contained"
              startIcon={<AddToPhotosIcon sx={{ fontSize: 20 }} />}
              onClick={handleOpen}
              sx={{
                height: 'auto',
                minWidth: 'auto',
                borderRadius: 1,
                display: createButton ? { xs: 'none', md: 'flex' } : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#00796b',
                '&:hover': {
                  backgroundColor: '#004d40',
                },
                flexGrow: 1,
              }}
            >
              Create
            </Button>
          )
        )}
      </Box>
    </Box>
  );
};

export default SearchBar;
